import {
  BARCODE_AUTOCOMPLETE,
  EQUIPMENT_MOBILE_LIST,
  EQUIPMENT_STATIONARY_LIST,
  STATES,
  TRACKING_MOBILE,
  TRACKING_STATIONARY
} from 'src/config/assetUrls';
import { COUNTERAGENTS, VEHICLES_CATEGORIES } from 'src/config/avlUrls';
import { Row } from 'src/components/Tables/types';
import EquipmentStateLabel from 'src/components/Labels/EquipmentStateLabel';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import { BARCODE_AUTOCOMPLETE_NAME } from 'src/views/asset/reports/const';
import { VEHICLES_AUTOCOMPLETE_CONFIG } from 'src/views/clearing/inspectionDocuments/InspectionDocumentsListView/const';

export enum TrackingTabsValues {
  Mobile = 1,
  Stationary = 2
}

export const mobileColumns = [
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Назва',
    value: 'equipmentName',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Модель',
    value: 'productType',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Виробник',
    value: 'manufacturer',
    sortable: true,
    width: '10%'
  },
  {
    label: 'IMEI ',
    value: 'imei',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragent',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Тип транспорту',
    value: 'vehicleType',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Реєстраційний номер ТЗ',
    value: 'licensePlate',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Стан обладнання',
    value: 'equipmentState',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => (
      <EquipmentStateLabel state={row.equipmentState} />
    )
  },
  {
    label: 'Дата монтажу',
    value: 'installationDate',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Дата останньої інспекції',
    value: 'lastInspectionDate',
    sortable: true,
    width: '10%'
  }
];

export const stationaryColumns = [
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Назва',
    value: 'equipmentName',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Модель',
    value: 'productType',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Виробник',
    value: 'manufacturer',
    sortable: true,
    width: '10%'
  },
  {
    label: 'IMEI ',
    value: 'imei',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragent',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Адреса',
    value: 'address',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Стан обладнання',
    value: 'equipmentState',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => (
      <EquipmentStateLabel state={row.equipmentState} />
    )
  },
  {
    label: 'Дата монтажу',
    value: 'installationDate',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Дата останньої інспекції',
    value: 'lastInspectionDate',
    sortable: true,
    width: '10%'
  }
];

const filters = [
  {
    name: 'counteragentId',
    label: 'Підрозділ',
    optionsURL: COUNTERAGENTS
  },
  {
    name: 'equipmentStateId',
    label: 'Стан',
    optionsURL: STATES
  }
];

export const TRACKING_MOBILE_TABLE_PATH = 'asset.tracking.mobileTable';
export const TRACKING_MOBILE_TABLE_NAME = 'TRACKING_MOBILE';

export const TRACKING_STATIONARY_TABLE_PATH = 'asset.tracking.stationaryTable';
export const TRACKING_STATIONARY_TABLE_NAME = 'TRACKING_STATIONARY';

const tableConfig = {
  routeUrl: '/asset/uninstallation/',
  toolbarActions: {},
  isRowClickEnabled: true
};

const BARCODE_FILTER = {
  name: 'barcode',
  value: 'name',
  label: 'Штрихкод',
  type: FilterType.Autocomplete,
  autocompleteConfig: {
    reducerName: BARCODE_AUTOCOMPLETE_NAME,
    reducerPath: 'asset.reports.barcodeAutocomplete',
    optionsApiUrl: BARCODE_AUTOCOMPLETE
  }
};

export const mobileTableConfig = {
  reducerName: TRACKING_MOBILE_TABLE_NAME,
  reducerPath: TRACKING_MOBILE_TABLE_PATH,
  apiUrl: TRACKING_MOBILE,
  filters: [
    {
      name: 'equipmentId',
      label: 'Назва обладнання',
      optionsURL: EQUIPMENT_MOBILE_LIST,
      value: 'id'
    },
    ...filters,
    {
      name: 'vehicleType',
      label: 'Тип ТЗ',
      optionsURL: VEHICLES_CATEGORIES,
      value: 'name'
    },
    {
      name: 'licensePlate',
      label: 'Реєстраційний номер ТЗ',
      type: FilterType.Autocomplete,
      autocompleteConfig: VEHICLES_AUTOCOMPLETE_CONFIG,
      value: 'name',
      width: 240
    },
    BARCODE_FILTER
  ],
  ...tableConfig,
  isRowClickEnabled: false
};

export const stationaryTableConfig = {
  reducerName: TRACKING_STATIONARY_TABLE_NAME,
  reducerPath: TRACKING_STATIONARY_TABLE_PATH,
  apiUrl: TRACKING_STATIONARY,
  filters: [
    {
      name: 'equipmentId',
      label: 'Назва обладнання',
      optionsURL: EQUIPMENT_STATIONARY_LIST,
      value: 'id'
    },
    ...filters,
    BARCODE_FILTER
  ],
  ...tableConfig,
  isRowClickEnabled: false
};
