import { object, number, date } from 'yup';
import moment from 'moment';
import {
  COUNTERAGENTS,
  VEHICLES_CATEGORIES,
  EMPLOYEES_AUTOCOMPLETE
} from 'src/config/avlUrls';
import {
  EQUIPMENT_GROUP_NAMES,
  EQUIPMENT_ACTIONS,
  REPORTS,
  EQUIPMENT,
  BARCODE_AUTOCOMPLETE
} from 'src/config/assetUrls';
import { MAIN_MENU } from 'src/constants';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { generateActionCrud } from 'src/components/Guards';
import { AutocompleteConfig } from 'src/components/Form/FormAutocomplete/types';

export const breadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset/routes'
  }
];

// Counteragents
export const REPORT_COUNTERAGENT_SELECT_NAME = ' REPORT_COUNTERAGENT_SELECT';
export const REPORT_COUNTERAGENT_SELECT_PATH =
  'asset.reports.reportCounteragentSelect';

export const REPORT_COUNTERAGENT_SELECT_CONFIG = {
  reducerPath: REPORT_COUNTERAGENT_SELECT_PATH,
  reducerName: REPORT_COUNTERAGENT_SELECT_NAME,
  optionsApiUrl: COUNTERAGENTS
};

// Actions
export const REPORT_ACTIONS_SELECT_NAME = ' REPORT_ACTIONS_SELECT';
export const REPORT_ACTIONS_SELECT_PATH = 'asset.reports.reportActionSelect';

export const REPORT_ACTIONS_SELECT_CONFIG = {
  reducerPath: REPORT_ACTIONS_SELECT_PATH,
  reducerName: REPORT_ACTIONS_SELECT_NAME,
  optionsApiUrl: EQUIPMENT_ACTIONS
};

// Equipments
export const REPORT_EQUIPMENTS_SELECT_NAME = 'REPORT_EQUIPMENTS_SELECT';
export const REPORT_EQUIPMENTS_AUTOCOMPLETE_PATH =
  'asset.reports.reportEquipmentSelect';

export const REPORT_EQUIPMENTS_AUTOCOMPLETE_CONFIG = {
  reducerPath: REPORT_EQUIPMENTS_AUTOCOMPLETE_PATH,
  reducerName: REPORT_EQUIPMENTS_SELECT_NAME,
  optionsApiUrl: EQUIPMENT
};

// Equipment Groups
export const REPORT_EQUIPMENT_GROUPS_SELECT_NAME =
  'REPORT_EQUIPMENT_GROUPS_SELECT';
export const REPORT_EQUIPMENT_GROUPS_SELECT_PATH =
  'asset.reports.reportEquipmentGroupSelect';

export const REPORT_EQUIPMENT_GROUPS_SELECT_CONFIG = {
  reducerPath: REPORT_EQUIPMENT_GROUPS_SELECT_PATH,
  reducerName: REPORT_EQUIPMENT_GROUPS_SELECT_NAME,
  optionsApiUrl: EQUIPMENT_GROUP_NAMES
};

// Vehicle Models
export const REPORT_VEHICLE_CATEGORIES_SELECT_NAME =
  'REPORT_VEHICLE_CATEGORIES_SELECT';
export const REPORT_VEHICLE_CATEGORIES_SELECT_PATH =
  'asset.reports.reportVehicleCategoriesSelect';

export const REPORT_VEHICLE_CATEGORIES_SELECT_CONFIG = {
  reducerPath: REPORT_VEHICLE_CATEGORIES_SELECT_PATH,
  reducerName: REPORT_VEHICLE_CATEGORIES_SELECT_NAME,
  optionsApiUrl: VEHICLES_CATEGORIES
};

// Employees autocomplete
export const REPORT_EMPLOYEES_AUTOCOMPLETE_NAME =
  'REPORT_EMPLOYEES_AUTOCOMPLETE';
export const REPORT_EMPLOYEES_AUTOCOMPLETE_PATH =
  'asset.reports.reportEmployeesAutocomplete';

export const REPORT_EMPLOYEES_AUTOCOMPLETE_CONFIG = {
  reducerPath: REPORT_EMPLOYEES_AUTOCOMPLETE_PATH,
  reducerName: REPORT_EMPLOYEES_AUTOCOMPLETE_NAME,
  optionsApiUrl: EMPLOYEES_AUTOCOMPLETE
};
export const COUNTERAGENTS_PATH = 'asset.reports.reportCounteragentSelect';
export const ACTIONS_PATH = 'asset.reports.reportActionSelect';
export const EQUIPMENTS_GROUP_PATH = 'asset.reports.reportEquipmentGroupSelect';
export const EQUIPMENTS_PATH = 'asset.reports.reportEquipmentSelect';
export const EXECUTANS_EMPLOYEE_PATH =
  'asset.reports.reportEmployeesAutocomplete.options';

export const BARCODE_AUTOCOMPLETE_NAME = 'BARCODE_AUTOCOMPLETE_NAME';
export const BARCODE_AUTOCOMPLETE_CONFIG: AutocompleteConfig = {
  reducerName: BARCODE_AUTOCOMPLETE_NAME,
  reducerPath: 'asset.reports.barcodeAutocomplete',
  optionsApiUrl: BARCODE_AUTOCOMPLETE
};

export const filterValidationSchema = object().shape({
  dateFrom: date()
    .nullable()
    .typeError(ERROR_MESSAGES.TYPE_ERROR),
  dateTo: date()
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .nullable()
    .test({
      message: ERROR_MESSAGES.TYPE_ERROR,
      test(value: string) {
        // if dates are not valid, this validation shouldn't be run
        return (
          !value ||
          !moment(value).isValid() ||
          !this.parent.dateFrom ||
          !moment(value).isBefore(moment(this.parent.dateFrom))
        );
      }
    }),
  counteragentId: number().nullable(),
  actionId: number().nullable(),
  equipmentGroupId: number().nullable(),
  equipmentId: number().nullable(),
  vehicleModelId: number().nullable()
});

export const DOWNLOAD_REPORTS = 'DOWNLOAD_REPORTS';

export const REPORTS_TABLE_PATH = 'asset.reports.table';
export const REPORTS_TABLE_NAME = 'REPORTS';

export const tableConfig = {
  reducerName: REPORTS_TABLE_NAME,
  reducerPath: REPORTS_TABLE_PATH,
  apiUrl: REPORTS,
  routeUrl: '/asset/reports/',
  deleteUrl: '',
  rowActions: {},
  toolbarActions: {},
  isRowClickEnabled: false
};

export const columns = [
  {
    label: 'Дата',
    value: 'date',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Дія',
    value: 'actionType',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Обладнання',
    value: 'equipmentName',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Тип обладнання',
    value: 'equipmentGroupName',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragentName',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Тип ТЗ',
    value: 'vehicleCategory',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Номер ТЗ',
    value: 'vehicleLicensePlate',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Модель ТЗ',
    value: 'vehicleModelName',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Відповідальна особа',
    value: 'responsibleEmployeeName',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Виконавець',
    value: 'executantEmployeeName',
    sortable: true,
    width: '8%'
  }
];

export const MIN_PAGE_SIZE = 1000;

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'reports'
  },
  ['download']
);
