export const CREATE_UNINSTALLATION_FORM_NAME = 'CREATE_UNINSTALLATION_FORM';
export const CREATE_UNINSTALLATION_FORM_PATH =
  'asset.uninstallation.create.data';
export const CREATE_UNINSTALLATION_FORM_CONFIG = {
  reducerName: CREATE_UNINSTALLATION_FORM_NAME,
  reducerPath: CREATE_UNINSTALLATION_FORM_PATH
};

export const UNINSTALLING_MOBILE_EQUIPMENT_NAME =
  'UNINSTALLING_MOBILE_EQUIPMENT_NAME';
export const UNINSTALLING_MOBILE_EQUIPMENT_PATH =
  'asset.uninstallation.create.mobileTable';

export const UNINSTALLING_MOBILE_TABLE_CONFIG = {
  reducerName: UNINSTALLING_MOBILE_EQUIPMENT_NAME,
  reducerPath: UNINSTALLING_MOBILE_EQUIPMENT_PATH,
  apiUrl: ``,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно видалено'
  }
};

export const UNINSTALLING_STATIONARY_EQUIPMENT_NAME =
  'UNINSTALLING_STATIONARY_EQUIPMENT_NAME';
export const UNINSTALLING_STATIONARY_EQUIPMENT_PATH =
  'asset.uninstallation.create.stationaryTable';

export const UNINSTALLING_STATIONARY_TABLE_CONFIG = {
  reducerName: UNINSTALLING_STATIONARY_EQUIPMENT_NAME,
  reducerPath: UNINSTALLING_STATIONARY_EQUIPMENT_PATH,
  apiUrl: ``,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно видалено'
  }
};
