import { createAsyncAction, createCustomAction } from 'typesafe-actions';
import { SelectConfig } from 'src/components/Select/types';

interface ValidationPayload {
  barcode: string;
  equipment: string;
  state: string;
  breakdown: string;
}

export const validateEquipment = createAsyncAction(
  '@BREAKDOWN/VALIDATE_EQUIPMENT_REQUEST',
  '@BREAKDOWN/VALIDATE_EQUIPMENT_SUCCESS',
  '@BREAKDOWN/VALIDATE_EQUIPMENT_FAILURE'
)<ValidationPayload, undefined, Error>();

export const getOptionsRequest = createCustomAction(
  '@SELECT/GET_OPTIONS_REQUEST',
  (config: SelectConfig) => ({
    config
  })
);
