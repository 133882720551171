import { createReducer, ActionType } from 'typesafe-actions';
import moment from 'moment';
import { DATEPICKER_DATE_FORMAT } from 'src/config';
import * as actions from './actions';
import { FormValues } from './types';

export const initialState: FormValues = {
  id: 0,
  documentNumber: '',
  uninstallationDate: moment().format(DATEPICKER_DATE_FORMAT),
  uninstallingEmployee: null,
  notes: ''
};

const uninstallationStepReducer = createReducer<
  FormValues,
  ActionType<typeof actions>
>(initialState).handleAction(actions.setFormValues, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default uninstallationStepReducer;
