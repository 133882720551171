import { SagaIterator } from 'redux-saga';
import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { EQUIPMENT, UNINSTALLATIONS } from 'src/config/assetUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { addRowsLocally } from 'src/components/Tables/TableView/store/actions';
import * as actions from './actions';
import { setLocalEquipmentsArray } from './utils';

export function* getEquipments({
  payload
}: ReturnType<typeof actions.getEquipments.request>): SagaIterator {
  const { barcode, mobileTableConfig, stationaryTableConfig } = payload;
  const {
    data: { mobileEquipment, stationaryEquipment }
  } = yield call(apiClient.get, `${EQUIPMENT}/barcode/${barcode}`);

  const equipmentsLength = mobileEquipment.length + stationaryEquipment.length;

  if (equipmentsLength > 1 || !equipmentsLength) {
    const message =
      equipmentsLength > 1
        ? `Знайдено кілька одиниць обладнання з однаковим штрихкодом (${barcode}). Перевірте обране обладнання.`
        : 'Такого штрихкоду не існує. Перевірте штрихкод.';

    yield put(
      enqueueSnackbar({
        key: 'uninstallation_equipments_snackbar',
        options: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          variant: 'warning'
        },
        message
      })
    );
  }

  yield put(
    addRowsLocally(mobileTableConfig, {
      rows: setLocalEquipmentsArray(mobileEquipment)
    })
  );
  yield put(
    addRowsLocally(stationaryTableConfig, {
      rows: setLocalEquipmentsArray(stationaryEquipment)
    })
  );
  yield put(actions.getEquipments.success());
}

export function* approveUninstallation({
  payload: id
}: ReturnType<typeof actions.approveUninstallation.request>): SagaIterator {
  yield call(apiClient.put, `${UNINSTALLATIONS}/${id}/approve`);
  yield put(actions.approveUninstallation.success());
}

export default function* UninstallationEquipmentsStepSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getEquipments.request),
      safe(getEquipments, actions.getEquipments.failure)
    ),
    takeEvery(
      getType(actions.approveUninstallation.request),
      safe(approveUninstallation, actions.approveUninstallation.failure)
    )
  ]);
}
