import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { EquipmentEditState } from '../types';

type EquipmentEditActions = ActionType<typeof actions>;

export const initialState: EquipmentEditState = {
  id: 0,
  name: '',
  equipmentGroupId: '',
  productType: '',
  manufacturer: '',
  inspectionDays: '',
  hasImei: '1',
  isInstalled: false
};

const EquipmentEditReducers = createReducer<
  EquipmentEditState,
  EquipmentEditActions
>(initialState).handleAction(
  actions.getEquipment.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default EquipmentEditReducers;
