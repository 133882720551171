import { createCustomAction } from 'typesafe-actions';

interface RegisterBreakdownsConfig {
  dataReducerPath: string;
  tableReducerPath: string;
}

export const registerBreakdownRequest = createCustomAction(
  '@BREAKDOWN/REGISTER_BREAKDOWN_REQUEST',
  (config: RegisterBreakdownsConfig) => ({
    config
  })
);

export const registerBreakdownSuccess = createCustomAction(
  '@BREAKDOWN/REGISTER_BREAKDOWN_SUCCESS',
  (config: RegisterBreakdownsConfig, payload: number) => ({
    config,
    payload
  })
);

export const registerBreakdownFailure = createCustomAction(
  '@BREAKDOWN/REGISTER_BREAKDOWN_FAILURE',
  (config: RegisterBreakdownsConfig, payload: Error) => ({
    config,
    payload
  })
);
