import { createAsyncAction } from 'typesafe-actions';
import { TableConfig } from 'src/components/Tables/TableWithPagination/types';
import {
  BillingDocumentRow,
  SuccessPayload
} from '../../BillingDocumentReportView/types';

export const updateBillingDocumentItemStatus = createAsyncAction(
  '@BILLING_DOCUMENTS_DETAILS/UPDATE_BILLING_DOCUMENT_ITEM_STATE_REQUEST',
  '@BILLING_DOCUMENTS_DETAILS/UPDATE_BILLING_DOCUMENT_ITEM_STATE_SUCCESS',
  '@BILLING_DOCUMENTS_DETAILS/UPDATE_BILLING_DOCUMENT_ITEM_STATE_FAILURE'
)<{ config: TableConfig; id: number; status: string }, undefined, Error>();

export const autogenerateBillingDocuments = createAsyncAction(
  '@BILLING_DOCUMENTS_DETAILS/AUTOGENERATE_BILLING_DOCUMENTS_REQUEST',
  '@BILLING_DOCUMENTS_DETAILS/AUTOGENERATE_BILLING_DOCUMENTS_SUCCESS',
  '@BILLING_DOCUMENTS_DETAILS/AUTOGENERATE_BILLING_DOCUMENTS_FAILURE'
)<undefined, undefined, Error>();

export const calculateBillingDocument = createAsyncAction(
  '@BILLING_DOCUMENTS_DETAILS/CALCULATE_BILLING_DOCUMENT_REQUEST',
  '@BILLING_DOCUMENTS_DETAILS/CALCULATE_BILLING_DOCUMENT_SUCCESS',
  '@BILLING_DOCUMENTS_DETAILS/CALCULATE_BILLING_DOCUMENT_FAILURE'
)<
  {
    id: number;
  },
  undefined,
  Error
>();

export const getBillingDocumentReport = createAsyncAction(
  '@BILLING_DOCUMENTS_DETAILS/GET_BILLING_DOCUMENT_REPORT_REQUEST',
  '@BILLING_DOCUMENTS_DETAILS/GET_BILLING_DOCUMENT_REPORT_SUCCESS',
  '@BILLING_DOCUMENTS_DETAILS/GET_BILLING_DOCUMENT_REPORT_FAILURE'
)<BillingDocumentRow, SuccessPayload, Error>();
