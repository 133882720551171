import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { SendEmailDialogState } from '../types';

export type SendEmailActions = ActionType<typeof actions>;

export const initialState: SendEmailDialogState = {
  isOpen: false,
  installationEmails: [],
  uninstallationEmails: []
};

const reducers = createReducer<SendEmailDialogState, SendEmailActions>(
  initialState
)
  .handleAction(actions.toggleSendEmailDialog, (state, { payload }) => ({
    ...state,
    ...payload
  }))
  .handleAction(actions.getPredefinedEmails.success, (state, { payload }) => ({
    ...state,
    ...payload
  }));

export default reducers;
