import { createCustomAction } from 'typesafe-actions';
import { TableConfig } from 'src/components/Tables/TableView/types';
import {
  CounteragentResponse,
  VehicleModelResponse,
  BreakdownsStepViewConfig,
  BreakdownDataPayload,
  BreakdownStepConfig,
  VehicleTypeResponse
} from './types';

export const getCounteragentRequest = createCustomAction(
  '@INSTALLATION_STEP/GET_COUNTERAGENT_REQUEST',
  (config: BreakdownStepConfig, payload: number) => ({
    config,
    payload
  })
);

export const getCounteragentSuccess = createCustomAction(
  '@INSTALLATION_STEP/GET_COUNTERAGENT_SUCCESS',
  (config: BreakdownStepConfig, payload: CounteragentResponse) => ({
    config,
    payload
  })
);

export const getCounteragentFailure = createCustomAction(
  '@INSTALLATION_STEP/GET_COUNTERAGENT_FAILURE',
  (config: BreakdownStepConfig, payload: Error) => ({
    config,
    payload
  })
);

export const getVehicleModelRequest = createCustomAction(
  '@INSTALLATION_STEP/GET_VEHICLE_MODEL_REQUEST',
  (config: BreakdownStepConfig, payload: number) => ({
    config,
    payload
  })
);

export const getVehicleModelSuccess = createCustomAction(
  '@INSTALLATION_STEP/GET_VEHICLE_MODEL_SUCCESS',
  (config: BreakdownStepConfig, payload: VehicleModelResponse) => ({
    config,
    payload
  })
);

export const getVehicleModelFailure = createCustomAction(
  '@INSTALLATION_STEP/GET_VEHICLE_MODEL_FAILURE',
  (config: BreakdownStepConfig, payload: Error) => ({
    config,
    payload
  })
);

export const getVehicleTypeRequest = createCustomAction(
  '@BREAKDOWN_STEP/GET_VEHICLE_TYPE_REQUEST',
  (config: BreakdownStepConfig, payload: number) => ({
    config,
    payload
  })
);

export const getVehicleTypeSuccess = createCustomAction(
  '@BREAKDOWN_STEP/GET_VEHICLE_TYPE_SUCCESS',
  (config: BreakdownStepConfig, payload: VehicleTypeResponse) => ({
    config,
    payload
  })
);

export const getVehicleTypeFailure = createCustomAction(
  '@BREAKDOWN_STEP/GET_VEHICLE_TYPE_FAILURE',
  (config: BreakdownStepConfig, payload: Error) => ({
    config,
    payload
  })
);

export const getBreakdownDataRequest = createCustomAction(
  '@BREAKDOWN_PAGE/GET_DATA_REQUEST',
  (config: BreakdownStepConfig, tableConfig: TableConfig) => ({
    config,
    tableConfig
  })
);

export const getBreakdownDataSuccess = createCustomAction(
  '@BREAKDOWN_PAGE/GET_DATA_SUCCESS',
  (config: BreakdownStepConfig, payload: BreakdownDataPayload) => ({
    config,
    payload
  })
);

export const getBreakdownDataFailure = createCustomAction(
  '@BREAKDOWN_PAGE/GET_DATA_FAILURE',
  (config: BreakdownStepConfig, payload: Error) => ({ config, payload })
);

export const setFormValues = createCustomAction(
  '@BREAKDOWN_STEP/SET_FORM_VALUES',
  (config: BreakdownStepConfig, values: BreakdownsStepViewConfig) => ({
    config,
    values
  })
);
