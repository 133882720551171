import { generateActionCrud } from 'src/components/Guards';
import { MAIN_MENU, NAV_BAR_HEADERS } from 'src/constants';
import { getDateDifference } from 'src/utils/date';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_HEADERS.uninstallation,
    url: '/asset/uninstallation'
  }
];

export const MAX_DAYS_AFTER_UNINSTALLATION = 40;
export const TOGGLE_DISABLED_TEXT = `Документ зафіксовано понад ${MAX_DAYS_AFTER_UNINSTALLATION} днів тому. Редагування заборонене.`;
export const isToggleDateRestricted = (date: string) =>
  getDateDifference(date) > MAX_DAYS_AFTER_UNINSTALLATION;

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'uninstallation'
  },
  ['download', 'resendDocument', 'uninstallationAllowEditing']
);
export enum UninstallationStatusEnum {
  Draft = 0,
  Fixed = 1
}

export const uninstallationStatusEnumStatusMap = {
  [UninstallationStatusEnum.Draft]: 'Draft',
  [UninstallationStatusEnum.Fixed]: 'Fixed'
};

export const UNINSTALLATION_STEPS = ['Демонтаж', 'Обладнання'];

export const uninstallationDocumentPdf = [
  {
    label: 'Виконавець',
    value: 'uninstallingEmployeeName'
  },
  {
    label: 'Примітка',
    value: 'notes'
  }
];

export const uninstallationMobileColumnsPdf = [
  {
    label: '№',
    value: 'index'
  },
  {
    label: 'Штрихкод',
    value: 'barcode'
  },
  {
    label: 'Назва',
    value: 'equipmentName'
  },
  {
    label: 'Модель',
    value: 'productType'
  },
  {
    label: 'Виробник',
    value: 'manufacturer'
  },
  {
    label: 'Підрозділ',
    value: 'counteragent'
  },
  {
    label: 'Тип транспорту',
    value: 'vehicleType'
  },
  {
    label: 'Модель ТЗ',
    value: 'vehicleModel'
  },
  {
    label: 'Реєстраційний номер ТЗ',
    value: 'licensePlate'
  }
];

export const uninstallationStationaryColumnsPdf = [
  {
    label: '№',
    value: 'index'
  },
  {
    label: 'Штрихкод',
    value: 'barcode'
  },
  {
    label: 'Назва',
    value: 'equipmentName'
  },
  {
    label: 'Модель',
    value: 'productType'
  },
  {
    label: 'Виробник',
    value: 'manufacturer'
  },
  {
    label: 'Підрозділ',
    value: 'counteragent'
  },
  {
    label: 'Адреса',
    value: 'address'
  },
  {
    label: 'Працівник',
    value: 'responsibleEmployeeName'
  }
];

export const uninstallationFieldsJson = [
  'documentNumber',
  'uninstallationDate',
  'uninstallingEmployeeName',
  'notes',
  {
    label: 'equipments',
    fields: [
      'equipmentGroup',
      'barcode',
      'equipmentName',
      'productType',
      'manufacturer',
      'counteragent',
      'vehicleType',
      'vehicleModel',
      'licensePlate',
      'address',
      'responsibleEmployeeName'
    ]
  }
];
