import { Row, TableConfigBase } from 'src/components/Tables/types';
import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';
import {
  UNINSTALLATION_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE,
  UNINSTALLATIONS
} from 'src/config/assetUrls';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import {
  UninstallationStatusEnum,
  uninstallationStatusEnumStatusMap
} from '../const';
import UninstallationSwitchColumn from './UninstallationSwitchColumn';

export const UNINSTALLATION_TABLE_PATH = 'asset.uninstallation.table';
export const UNINSTALLATION_TABLE_NAME = 'UNINSTALLATION';

export const TABLE_CONFIG_BASE = {
  reducerName: UNINSTALLATION_TABLE_NAME,
  reducerPath: UNINSTALLATION_TABLE_PATH,
  apiUrl: UNINSTALLATIONS
};

export const getColumns = (isShowEditAllowed: boolean) => {
  const staticColumns = [
    {
      label: 'Номер документа',
      value: 'documentNumber',
      sortable: true,
      width: '20%'
    },
    {
      label: 'Дата демонтажу',
      value: 'uninstallationDate',
      sortable: true,
      width: '15%'
    },
    {
      label: 'Кількість обладнання',
      value: 'uninstalledEquipmentCount',
      sortable: true,
      width: '20%'
    },
    {
      label: 'Хто демонтував',
      value: 'uninstallingEmployeeName',
      sortable: true,
      width: '15%'
    },
    {
      label: 'Статус документа',
      value: 'statusId',
      sortable: true,
      width: '15%',
      changeValue: (row: Row) =>
        changeStatusColumn({
          ...row,
          status:
            uninstallationStatusEnumStatusMap[
              row.statusId as UninstallationStatusEnum
            ]
        })
    }
  ];

  if (isShowEditAllowed) {
    staticColumns.push({
      label: '',
      value: '',
      sortable: false,
      width: '5%',
      changeValue: row => (
        <UninstallationSwitchColumn
          row={row}
          config={TABLE_CONFIG_BASE as TableConfigBase}
        />
      )
    });
  }

  return staticColumns;
};

export const RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME =
  'INSTALLATION_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE';

export const filters = [
  {
    type: FilterType.Date,
    name: 'uninstallationDate',
    label: 'Дата',
    datePickerProps: {
      disableFuture: true
    }
  },
  {
    name: 'uninstallingEmployeeId',
    label: 'Хто демонтував',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME,
      reducerPath: 'asset.uninstallation.responsibleEmployeeAutocomplete',
      optionsApiUrl: UNINSTALLATION_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE
    }
  },
  {
    name: 'statusId',
    label: 'Статус документа',
    options: [
      {
        label: 'Зафіксований',
        value: UninstallationStatusEnum.Fixed
      },
      {
        label: 'Чернетка',
        value: UninstallationStatusEnum.Draft
      }
    ]
  }
];

export const deleteWarningAppendix =
  'Все демонтоване обладнання буде повернене у моніторинг';
