import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { BREAKDOWNS } from 'src/config/assetUrls';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import {
  selectBreakdownRowsForEdit,
  selectBreakdownsEditData
} from '../../BreakdownsCreateView/EquipmentStepView/store/selectors';
import { selectBreakdownId } from '../../BreakdownsCreateView/BreakdownsStepView/store/selectors';
import { normalizeBreakdownItem } from '../helpers/helpers';
import { EDIT_BREAKDOWN_FORM_PATH, EDIT_FORM_PATH } from '../const';

export function* updateBreakdown({
  config
}: ReturnType<typeof actions.updateBreakdownRequest>): SagaIterator {
  const id = yield select(selectBreakdownId);
  const breakdown = yield select(
    selectBreakdownsEditData(EDIT_BREAKDOWN_FORM_PATH)
  );
  const breakdownItem = yield select(
    selectBreakdownRowsForEdit(EDIT_FORM_PATH)
  );

  yield call(
    apiClient.put,
    `${BREAKDOWNS}/${id}`,
    {
      registrationDate: breakdown.installDate,
      reportedBy: breakdown.installingEmployee,
      notes: breakdown.notes,
      registeredByEmployeeName: breakdown.acceptingEmployee?.name,
      registeredByEmployeeId: breakdown.acceptingEmployee?.id,
      breakdownItems: normalizeBreakdownItem(breakdownItem)
    },
    { retryAction: actions.updateBreakdownRequest(config) }
  );

  yield put(navigateTo({ url: `/asset/breakdowns` }));
  yield put(
    enqueueSnackbar({
      key: 'breakdown_edit',
      message: 'Документ відредаговано',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.updateBreakdownSuccess(config));
}

export default function* BreakdownEditSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.updateBreakdownRequest),
    safe(updateBreakdown, actions.updateBreakdownFailure)
  );
}
