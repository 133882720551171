import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { UNINSTALLATIONS } from 'src/config/assetUrls';
import { addRowsLocally } from 'src/components/Tables/TableView/store/actions';
import * as actions from './actions';
import * as selectors from './selectors';
import {
  EDIT_UNINSTALLATION_FORM_CONFIG,
  EDIT_UNINSTALLING_MOBILE_TABLE_CONFIG,
  EDIT_UNINSTALLING_STATIONARY_TABLE_CONFIG,
  EDIT_UNINSTALLING_MOBILE_EQUIPMENT_PATH,
  EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_PATH
} from '../const';
import { normalizeFormResponse } from './utils';
import { setFormValues } from '../../UninstallationStep/store/actions';
import { selectListCreateData } from '../../UninstallationEquipmentsStep/store/selectors';
import { selectFormCreateData } from '../../UninstallationStep/store/selectors';

export function* updateUninstallation(): SagaIterator {
  const [id, formData, mobileEquipments, stationaryEquipments] = yield all([
    select(selectors.selectUninstallationId),
    select(selectFormCreateData(EDIT_UNINSTALLATION_FORM_CONFIG.reducerPath)),
    select(selectListCreateData(EDIT_UNINSTALLING_MOBILE_EQUIPMENT_PATH)),
    select(selectListCreateData(EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_PATH))
  ]);

  yield call(
    apiClient.put,
    `${UNINSTALLATIONS}/${id}`,
    {
      ...formData,
      equipmentIds: [...mobileEquipments, ...stationaryEquipments],
      status: StatusEnum.Draft
    },
    {
      retryAction: actions.updateUninstallation.request()
    }
  );

  yield put(actions.updateUninstallation.success());
}

export function* getUninstallationData(): SagaIterator {
  const id = yield select(selectors.selectUninstallationId);

  const { data } = yield call(apiClient.get, `${UNINSTALLATIONS}/${id}`, {
    retryAction: actions.getUninstallationData.request()
  });
  const { mobileEquipment, stationaryEquipment, ...formValues } = data;

  yield put(
    addRowsLocally(EDIT_UNINSTALLING_MOBILE_TABLE_CONFIG(id), {
      rows: mobileEquipment
    })
  );
  yield put(
    addRowsLocally(EDIT_UNINSTALLING_STATIONARY_TABLE_CONFIG(id), {
      rows: stationaryEquipment
    })
  );

  yield put(
    setFormValues(
      EDIT_UNINSTALLATION_FORM_CONFIG,
      normalizeFormResponse(formValues)
    )
  );
  yield put(actions.getUninstallationData.success());
}

export default function* UninstallationDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.updateUninstallation.request),
      safe(updateUninstallation, actions.updateUninstallation.failure)
    ),
    takeEvery(
      getType(actions.getUninstallationData.request),
      safe(getUninstallationData, actions.getUninstallationData.failure)
    )
  ]);
}
