import { SagaIterator } from 'redux-saga';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { EQUIPMENT } from 'src/config/assetUrls';
import * as actions from './actions';
import { selectCounteragentId, selectVehicleId } from './selectors';
import { BREAKDOWNS_DATA_REDUCER_PATH } from '../const';

export function* validateEquipment({
  payload
}: ReturnType<typeof actions.validateEquipment.request>): SagaIterator {
  const counteragentId = select(
    selectCounteragentId(BREAKDOWNS_DATA_REDUCER_PATH)
  );
  const vehicleId = select(selectVehicleId(BREAKDOWNS_DATA_REDUCER_PATH));

  yield call(
    apiClient.post,
    `${EQUIPMENT}/installed/countragent/${counteragentId}${
      vehicleId ? `/vehicle/${vehicleId}` : ''
    }`,
    payload,
    {
      retryAction: actions.validateEquipment.request(payload)
    }
  );
  yield put(actions.validateEquipment.success());
}

export default function* EquipmentBreakdownStepSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.validateEquipment.request),
    safe(validateEquipment, actions.validateEquipment.failure)
  );
}
