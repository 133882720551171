import { API_VERSION } from './index';

const SUB_DOMAIN = `/asset-management/${API_VERSION}`;

// equipment
export const EQUIPMENT = `${SUB_DOMAIN}/equipments`;
export const EQUIPMENT_GROUP_NAMES = `${EQUIPMENT}/groups`;
export const EQUIPMENT_AUTOCOMPLETE = `${EQUIPMENT}/autocomplete`;
export const EQUIPMENT_MOBILE_LIST = `${EQUIPMENT}/mobile/list`;
export const EQUIPMENT_STATIONARY_LIST = `${EQUIPMENT}/stationary/list`;
export const EQUIPMENT_ACTIONS = `${EQUIPMENT}/actions`;

// breakdownsTypes
export const BREAKDOWNS_TYPES = `${SUB_DOMAIN}/breakdown-types`;

// breakdowns
export const BREAKDOWNS = `${SUB_DOMAIN}/breakdowns`;
export const REGISTERED_EMPLOYEE = `${BREAKDOWNS}/registered-by-employees`;
export const REGISTERED_EMPLOYEE_AUTOCOMPLETE = `${REGISTERED_EMPLOYEE}/autocomplete`;

// installation
export const INSTALLATIONS = `${SUB_DOMAIN}/installations`;
export const INSTALLATIONS_EQUIPMENT_VALIDATION = `${INSTALLATIONS}/equipment-uniqueness`;
export const RESPONSIBLE_EMPLOYEE = `${INSTALLATIONS}/responsible-employees`;
export const RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE = `${RESPONSIBLE_EMPLOYEE}/autocomplete`;

// uninstallation
export const UNINSTALLATIONS = `${SUB_DOMAIN}/uninstallations`;
export const UNINSTALLATIONS_RESPONSIBLE_EMPLOYEE = `${UNINSTALLATIONS}/responsible-employees`;
export const UNINSTALLATION_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE = `${UNINSTALLATIONS_RESPONSIBLE_EMPLOYEE}/autocomplete`;

// inspections
export const INSPECTIONS = `${SUB_DOMAIN}/inspections`;
export const INSPECTIONS_EQUIPMENT = `${INSPECTIONS}/equipment`;

// send email
export const SEND_EMAIL = `${SUB_DOMAIN}/emails/send`;

// tracking
export const TRACKING_MOBILE = `${SUB_DOMAIN}/tracking/mobile`;
export const TRACKING_STATIONARY = `${SUB_DOMAIN}/tracking/stationary`;

// states
export const STATES = `${EQUIPMENT}/states`;

// reports
export const REPORTS = `${SUB_DOMAIN}/reports/equipment-history`;
export const REPORTS_PAGE = `${REPORTS}/page`;
export const BARCODE_AUTOCOMPLETE = `${INSTALLATIONS}/autocomplete`;
