import {
  ModuleTypes,
  AvlSubTypes,
  AssetsSubTypes,
  ClearingSubTypes,
  ActionTypes,
  AdministrationSubTypes,
  UserManagementSubTypes
} from './types';

export const modules: Record<ModuleTypes, number> = {
  avl: 1,
  assets: 2,
  clearing: 3,
  userManagement: 4,
  administration: 5
};

export const avlSub: Record<AvlSubTypes, number> = {
  stops: 2,
  counteragents: 3,
  dangerousZones: 4,
  employees: 5,
  routes: 7,
  vehicleModels: 9,
  vehicles: 10,
  schedules: 11,
  rides: 13,
  reports: 14
};

export const assetsSub: Record<AssetsSubTypes, number> = {
  equipment: 1,
  breakdownsTypes: 2,
  installation: 3,
  tracking: 4,
  uninstallation: 5,
  inspections: 6,
  breakdowns: 7,
  reports: 8
};

export const clearingSub: Record<ClearingSubTypes, number> = {
  kpi: 1,
  penalty: 2,
  tariff: 3,
  penaltyDocument: 4,
  coefficientsAdjustments: 5,
  inspectionDocuments: 6,
  billingDocuments: 7,
  kpiGroup: 8,
  billingDetails: 9
};

export const administrationSub: Record<AdministrationSubTypes, number> = {
  routeSettings: 1,
  assetSettings: 2,
  clearingSettings: 3,
  apiKeysSettings: 4
};

export const userManagementSub: Record<UserManagementSubTypes, number> = {
  usersSettings: 1,
  usersLogs: 5
};

export const actions: Record<ActionTypes, number> = {
  create: 1,
  read: 2,
  update: 3,
  delete: 4,
  download: 5,
  register: 6,
  unregister: 7,
  generateAndDownload: 7,
  makeStopUnavailable: 6,
  changeRideMode: 6,
  terminateDeparture: 7,
  rideChangeover: 8,
  uninstallationAllowEditing: 8,
  installationAllowEditing: 8,
  breakdownsAllowEditing: 8,
  billingDocumentsAllowEditing: 6,
  fix: 8,
  unfix: 9,
  resendDocument: 10,
  conductInspection: 11,
  viewScheduleStatuses: 6,
  export: 9
};

export const SUB_MODULES_MAP: Record<ModuleTypes, Record<string, number>> = {
  avl: avlSub,
  assets: assetsSub,
  clearing: clearingSub,
  administration: administrationSub,
  userManagement: userManagementSub
};
