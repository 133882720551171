import Label from 'src/components/Labels/Label';
import { STATUS_CONFIG } from 'src/constants';
import { Selection, Sorter, ToolbarActionDisabled } from './types';
import { Row, RowIsActiveType } from '../types';

export const getSelection = (
  checked: boolean,
  id: number,
  selection: Selection,
  rowCount: number
) => {
  const { selectAll, included, excluded } = selection;

  if (selectAll) {
    if (checked) {
      if (excluded.length + 1 === rowCount) {
        /* all items excluded */
        return { selectAll: false, included: [], excluded: [] };
      }

      /* add to excluded */
      return {
        selectAll: true,
        included: [],
        excluded: [...excluded, id]
      };
    }

    /* remove from excluded */
    return {
      selectAll: true,
      included: [],
      excluded: excluded.filter((item: string | number) => item !== id)
    };
  }

  if (!checked) {
    if (included.length + 1 === rowCount) {
      /* all items included */
      return { selectAll: true, included: [], excluded: [] };
    }

    /* add to included */
    return {
      selectAll: false,
      included: [...included, id],
      excluded: []
    };
  }

  /* remove from included */
  return {
    selectAll: false,
    included: included.filter((item: string | number) => item !== id),
    excluded: []
  };
};

export const getSorters = (sorters: Sorter[], column: string): Sorter[] => {
  const sorter = sorters.find(
    ({ propertyName }: Sorter) => propertyName === column
  );
  const isAsc = !sorter || (sorter && sorter.order === 'DESC');

  return [
    {
      propertyName: column,
      order: isAsc ? 'ASC' : 'DESC'
    }
  ];
};

const isFunction = (prop: Function | boolean): prop is Function => {
  return typeof prop === 'function';
};

export const isActionEnabled = (isActive: RowIsActiveType, row: Row) => {
  if (isFunction(isActive)) {
    return isActive(row);
  }

  return isActive;
};

export const isActionDisabled = (
  rows: Row[],
  disabled?: ToolbarActionDisabled
) => {
  if (disabled && isFunction(disabled)) {
    return disabled(rows);
  }

  return disabled;
};

export const changeStatusColumn = (row: Row) => {
  const { color, label } = STATUS_CONFIG[row.status.toLowerCase()];
  return <Label color={color}>{label}</Label>;
};
