import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import {
  BILLING_DETAILS,
  BILLING_DOCUMENTS_CHANGE_STATUS,
  BILLING_DOCUMENTS_AUTOGENERATE,
  BILLING_DOCUMENT_CALCULATE
} from 'src/config/clearingUrls';
import { getDataRequest } from 'src/components/Tables/TableWithPagination/store/actions';
import * as tableSelectors from 'src/components/Tables/TableWithPagination/store/selectors';
import { GetDataRequestPayload } from 'src/components/Tables/TableWithPagination/types';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import { MIN_PAGE_SIZE } from '../const';
import { BillingDocumentRow } from '../../BillingDocumentReportView/types';

export function* updateBillingDocumentItemStatus({
  payload: { id, config, status }
}: ReturnType<
  typeof actions.updateBillingDocumentItemStatus.request
>): SagaIterator {
  const [selectList, selectSorters, selectFilters] = yield all([
    call(tableSelectors.selectList, config.reducerPath),
    call(tableSelectors.selectSorters, config.reducerPath),
    call(tableSelectors.selectFilters, config.reducerPath)
  ]);
  const [{ currentPage, pageSize }, sorters, filters] = yield all([
    select(selectList),
    select(selectSorters),
    select(selectFilters)
  ]);

  yield call(
    apiClient.put,
    BILLING_DOCUMENTS_CHANGE_STATUS(id, status),
    {},
    {
      retryAction: actions.updateBillingDocumentItemStatus.request({
        id,
        config,
        status
      })
    }
  );

  yield put(
    getDataRequest(config, {
      page: currentPage,
      pageSize,
      sorters,
      filters,
      resetSelection: true
    })
  );

  yield put(actions.updateBillingDocumentItemStatus.success());
}

export function* autogenerateBillingDocuments(): SagaIterator {
  yield call(apiClient.post, BILLING_DOCUMENTS_AUTOGENERATE, {
    retryAction: actions.autogenerateBillingDocuments.request()
  });

  yield put(actions.autogenerateBillingDocuments.success());

  yield put(
    enqueueSnackbar({
      key: 'autogenerate_billing_success',
      message: 'Автоматичне нарахування розпочато',
      options: {
        variant: 'success'
      }
    })
  );
}

export function* calculateBillingDocument({
  payload
}: ReturnType<typeof actions.calculateBillingDocument.request>): SagaIterator {
  yield call(
    apiClient.post,
    BILLING_DOCUMENT_CALCULATE(payload.id),
    {},
    {
      retryAction: actions.calculateBillingDocument.request(payload)
    }
  );

  yield put(actions.calculateBillingDocument.success());

  yield put(
    enqueueSnackbar({
      key: 'calculate_billing_success',
      message: 'Нараховано',
      options: {
        variant: 'success'
      }
    })
  );
}

export function* fetchBillingDocumentReport(
  payload: BillingDocumentRow,
  pageSize = MIN_PAGE_SIZE
) {
  const { counteragentId, id } = payload;

  const pageRequest: GetDataRequestPayload = {
    page: 1,
    pageSize
  };
  const { data } = yield call(
    apiClient.post,
    `${BILLING_DETAILS}/vehicle/page`,
    {
      pageRequest
    },
    {
      params: {
        counteragentId,
        documentId: id
      }
    }
  );

  return data;
}

export function* getBillingDocumentReport({
  payload
}: ReturnType<typeof actions.getBillingDocumentReport.request>): SagaIterator {
  let data = yield call(fetchBillingDocumentReport, payload);

  if (data.rowCount > MIN_PAGE_SIZE) {
    data = yield call(fetchBillingDocumentReport, payload, data.rowCount);
  }

  yield put(
    actions.getBillingDocumentReport.success({
      billingDocument: data.results,
      documentDetails: payload
    })
  );
}

export default function* BillingDocumentListSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.updateBillingDocumentItemStatus.request),
      safe(
        updateBillingDocumentItemStatus,
        actions.updateBillingDocumentItemStatus.failure
      )
    ),
    takeEvery(
      getType(actions.autogenerateBillingDocuments.request),
      safe(
        autogenerateBillingDocuments,
        actions.autogenerateBillingDocuments.failure
      )
    ),
    takeEvery(
      getType(actions.calculateBillingDocument.request),
      safe(calculateBillingDocument, actions.calculateBillingDocument.failure)
    ),
    takeEvery(
      getType(actions.getBillingDocumentReport.request),
      safe(getBillingDocumentReport, actions.getBillingDocumentReport.failure)
    )
  ]);
}
