import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Lens as CircleIcon } from '@material-ui/icons';
import { BreakdownStateEnum } from '@eticket/asset-management-api-contracts';
import { equipmentStateMap, equipmentColorStateMap } from 'src/constants';

const useStyles = makeStyles<Theme, { state: BreakdownStateEnum }>({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  circleIcon: ({ state }) => ({
    marginRight: 10,
    fontSize: 8,
    color: equipmentColorStateMap[state]
  })
});

// this label referes to both equipment and breakdown, since they share the same states
const EquipmentStateLabel = ({ state }: { state: BreakdownStateEnum }) => {
  const classes = useStyles({ state });

  return (
    <Box className={classes.root}>
      <CircleIcon className={classes.circleIcon} />
      <Typography variant="h6">{equipmentStateMap[state]}</Typography>
    </Box>
  );
};

export default EquipmentStateLabel;
