import { createCustomAction } from 'typesafe-actions';

interface RegisterInstallationConfig {
  dataReducerPath: string;
  tableReducerPath: string;
}

export const registerInstallationRequest = createCustomAction(
  '@INSTALLATION/REGISTER_INSTALLATION_REQUEST',
  (config: RegisterInstallationConfig, resolve: (id: string) => void) => ({
    config,
    resolve
  })
);

export const registerInstallationSuccess = createCustomAction(
  '@INSTALLATION/REGISTER_INSTALLATION_SUCCESS',
  (config: RegisterInstallationConfig, payload: number) => ({
    config,
    payload
  })
);

export const registerInstallationFailure = createCustomAction(
  '@INSTALLATION/REGISTER_INSTALLATION_SUCCESS',
  (config: RegisterInstallationConfig, payload: Error) => ({
    config,
    payload
  })
);
