import { object, string, number } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { EQUIPMENT_AUTOCOMPLETE } from 'src/config/assetUrls';
import { Row } from 'src/components/Tables/types';
import Label from 'src/components/Labels/Label';

export const EQUIPMENT_NAME = 'EQUIPMENT_AUTOCOMPLETE';
export const EQUIPMENT_PATH = 'asset.installation.equipmentAutocomplete';

export const EQUIPMENT_CONFIG = (equipmentId: number) => ({
  optionsApiUrl: `${EQUIPMENT_AUTOCOMPLETE}/${equipmentId}`,
  reducerName: EQUIPMENT_NAME,
  reducerPath: EQUIPMENT_PATH
});

export const initialFormState = {
  isOpen: false,
  isEditMode: false,
  values: {}
};

export const columns = [
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '25%'
  },
  {
    label: 'Назва обладнання',
    value: 'equipment.name',
    sortable: true,
    width: '25%'
  },
  {
    label: 'IMEI',
    value: 'imei',
    sortable: true,
    width: '30%',
    changeValue: (row: Row) =>
      row.equipment.hasImei && !row.imei ? (
        <Label color="warning">Потрібно просканувати</Label>
      ) : (
        row.imei || null
      )
  }
];

export const equipmentFormValidationScheme = object().shape({
  barcode: string()
    .matches(/^[a-zA-Z0-9]+$/, ERROR_MESSAGES.TYPE_ERROR)
    .required(ERROR_MESSAGES.REQUIRED),
  equipment: object()
    .nullable(false)
    .shape({
      id: number()
    })
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  imei: string()
    .when(['equipment.hasImei', '$imei'], {
      is: (hasImei: boolean, imei: string) => hasImei && imei,
      then: string()
        .matches(/^\d{15}$/, 'Значення повинно містити 15 цифр')
        .required(ERROR_MESSAGES.REQUIRED)
    })
    .when(['$imei', '$equipmentsImei', '$isSame'], {
      is: (imei: string, equipmentsImei: string[], isSame: boolean) =>
        !isSame && equipmentsImei.includes(imei),
      then: string().test({
        test() {
          throw this.createError({
            message: ERROR_MESSAGES.UNIQUE,
            path: 'imei'
          });
        }
      })
    })
    .when(['$equipmentsImei', 'equipment.hasImei', '$initImei'], {
      is: (equipmentsImei: string[], hasImei: boolean, initImei: boolean) =>
        !initImei && hasImei && equipmentsImei.length >= 1,
      then: string().test({
        test() {
          throw this.createError({
            message: ERROR_MESSAGES.ONLY_ONE_ITEM_WITH_IMEI,
            path: 'imei'
          });
        }
      })
    })
});
