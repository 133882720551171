import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { StatusCodes } from 'http-status-codes';
import { CancelTokenSource } from 'axios';
import { apiClient } from 'src/utils/api';
import { safe, withCancel } from 'src/utils/sagas';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import * as selectors from './selectors';
import { TableConfig } from '../types';

export function* selectTableOptions({
  reducerPath
}: TableConfig): SagaIterator {
  const [
    selectList,
    selectSorters,
    selectFilters,
    selectSelection
  ] = yield all([
    call(selectors.selectList, reducerPath),
    call(selectors.selectSorters, reducerPath),
    call(selectors.selectFilters, reducerPath),
    call(selectors.selectSelection, reducerPath)
  ]);
  const [{ currentPage, pageSize }, sorters, filters, selection] = yield all([
    select(selectList),
    select(selectSorters),
    select(selectFilters),
    select(selectSelection)
  ]);

  return {
    requestPayload: { page: currentPage, pageSize, sorters, filters },
    ...selection
  };
}

export function* getDataList({
  payload,
  config,
  cancelSource
}: ReturnType<typeof actions.getDataRequest> & {
  cancelSource: CancelTokenSource;
}): SagaIterator {
  const {
    page,
    pageSize,
    sorters = [],
    filters = [],
    resetSelection
  } = payload;
  const params = config.apiUrlParams || {};

  const urlParams = Object.keys(params)
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&');

  const { data } = yield call(
    apiClient.post,
    `${config.apiUrl}/page?${urlParams}`,
    {
      page,
      pageSize,
      sorters,
      filters
    },
    {
      cancelToken: cancelSource.token,
      retryAction: actions.getDataRequest(config, payload)
    }
  );

  yield put(
    actions.getDataSuccess(config, {
      list: data,
      sorters,
      filters,
      resetSelection
    })
  );
}

export function* deleteRow({
  payload,
  config
}: ReturnType<typeof actions.deleteRowRequest>): SagaIterator {
  const { id, name } = payload;
  const { apiUrl } = config;
  const { requestPayload } = yield call(selectTableOptions, config);

  yield call(apiClient.delete, `${apiUrl}/${id}`, {
    retryAction: actions.deleteRowRequest(config, payload)
  });
  yield put(
    enqueueSnackbar({
      key: 'delete_row_success',
      message: `${name || ''} видалено`,
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.deleteRowSuccess(config));
  yield put(
    actions.getDataRequest(config, {
      ...requestPayload,
      resetSelection: true
    })
  );
}

export function* deleteSelectedRows({
  config
}: ReturnType<typeof actions.deleteSelectedRowsRequest>): SagaIterator {
  const { selectAll, included, excluded, requestPayload } = yield call(
    selectTableOptions,
    config
  );

  const { status } = yield call(apiClient.delete, `${config.apiUrl}/bulk`, {
    data: {
      strategy: selectAll ? 'AllExcept' : 'OnlySpecified',
      ids: selectAll ? excluded : included
    },
    retryAction: actions.deleteSelectedRowsRequest(config)
  });

  const isPartialContentStatus = status === StatusCodes.PARTIAL_CONTENT;

  yield put(
    enqueueSnackbar({
      key: 'delete_selected_rows_success',
      message: isPartialContentStatus
        ? 'Деякі записи не були видалені через встановлені обмеження'
        : 'Успішно видалено',
      options: {
        variant: isPartialContentStatus ? 'error' : 'success'
      }
    })
  );
  yield put(actions.deleteSelectedRowsSuccess(config));

  yield put(
    actions.getDataRequest(config, {
      ...requestPayload,
      resetSelection: true
    })
  );
}

export function* getFilterOptions({
  payload,
  config
}: ReturnType<typeof actions.getFilterOptionsRequest>): SagaIterator {
  const { data } = yield call(apiClient.get, payload.optionsURL, {
    retryAction: actions.getFilterOptionsRequest(config, payload)
  });

  yield put(
    actions.getFilterOptionsSuccess(config, {
      filterName: payload.filterName,
      options: data
    })
  );
}

export default function* TableSagas(): SagaIterator {
  yield all([
    yield takeEvery(getType(actions.getDataRequest), action =>
      withCancel({
        action,
        failureAction: actions.getDataFailure,
        cancelActionType: getType(actions.tableUnmount),
        saga: getDataList
      })
    ),
    takeEvery(
      getType(actions.deleteRowRequest),
      safe(deleteRow, actions.deleteRowFailure)
    ),
    takeEvery(
      getType(actions.deleteSelectedRowsRequest),
      safe(deleteSelectedRows, actions.deleteSelectedRowsFailure)
    ),
    takeEvery(
      getType(actions.getFilterOptionsRequest),
      safe(getFilterOptions, actions.getFilterOptionsFailure)
    )
  ]);
}
