import { IClearingDomainErrorCode } from '@eticket/clearing-api-contracts';
import { IAvlDomainErrorCodes } from '@eticket/avl-api-contracts';
import { IAssetManagementDomainErrorCodes } from '@eticket/asset-management-api-contracts';

export const ERROR_MESSAGES = {
  ONLY_ONE_ITEM_WITH_IMEI: 'Обладнання з ІМЕІ вже додане',
  REQUIRED: "Обов'язкове поле",
  NUMBER: 'Будь ласка, введіть число',
  AMOUNT_TYPE_ERROR:
    'Значення не повинно містити більше 6 цифр, з урахуванням 2 знаків після крапки, наприклад - 1000.50',
  AMOUNT_GREATER_ERROR: 'Значення повинно бути більше 0',
  TYPE_ERROR: 'Введіть коректне значення',
  UNIQUE: 'Вказане значення уже існує',
  INT: 'Введіть ціле число',
  MIN_DATE_TOMORROW: 'Виберіть дату в майбутньому',
  MIN_TIME_IN_FUTURE: 'Виберіть час в майбутньому'
};

export interface ClearingErrorCodeMap extends IClearingDomainErrorCode {
  [key: string]: string | undefined;
}
export interface AvlErrorCodeMap extends IAvlDomainErrorCodes {
  [key: string]: string | undefined;
}
export interface AssetErrorCodeMap extends IAssetManagementDomainErrorCodes {
  [key: string]: string | undefined;
}

export const CLEARING_ERROR_MESSAGES: ClearingErrorCodeMap = {
  uniqueError: 'Вказане значення уже існує',
  kpiParemeters_TotalWeightShouldBeLessOrEqual100:
    'KpiParemeters_TotalWeightShouldBeLessOrEqual100',
  asyncPredicateValidator: 'AsyncPredicateValidator',
  creditCardValidator: 'CreditCardValidator',
  customValidator: 'CustomValidator',
  emailValidator: 'EmailValidator',
  emptyValidator: 'EmptyValidator',
  enumValidator: 'EnumValidator',
  equalValidator: 'EqualValidator',
  exclusiveBetweenValidator: 'ExclusiveBetweenValidator',
  greaterThanOrEqualValidator: 'GreaterThanOrEqualValidator',
  greaterThanValidator: 'Значення повинно бути більше 0',
  inclusiveBetweenValidator: 'InclusiveBetweenValidator',
  lengthValidator: 'LengthValidator',
  lessThanOrEqualValidator: 'LessThanOrEqualValidator',
  lessThanValidator: 'LessThanValidator',
  noopPropertyValidator: 'NoopPropertyValidator',
  notEmptyValidator: "Обов'язкове поле",
  notEqualValidator: 'NotEqualValidator',
  notNullValidator: 'NotNullValidator',
  nullValidator: 'NullValidator',
  onFailureValidator: 'OnFailureValidator',
  polymorphicValidator: 'PolymorphicValidator',
  predicateValidator: 'PredicateValidator',
  propertyValidator: 'PropertyValidator',
  regularExpressionValidator: 'RegularExpressionValidator',
  scalePrecisionValidator: 'test',
  stringEnumValidator: 'StringEnumValidator',
  notFoundValidator: 'NotFoundValidator',
  conflictValidator: 'ConflictValidator',
  forbiddenValidator: 'ForbiddenValidator',
  unauthorizedValidator: 'UnauthorizedValidator',
  internalServerErrorValidator: 'InternalServerErrorValidator',
  invalidPassengerCapacity: 'invalidPassengerCapacity',
  duplicatingDates: 'duplicatingDates',
  invalidTariffId: 'invalidTariffId',
  notFound: 'notFound',
  conflict: 'conflict',
  forbidden: 'forbidden',
  unauthorized: 'unauthorized',
  internalServerError: 'internalServerError'
};

export const AVL_ERROR_MESSAGES: AvlErrorCodeMap = {
  uniqueError: 'Вказане значення уже існує',
  invalidLongitude: 'invalidLongitude',
  invalidLatitude: 'invalidLatitude',
  invalidPolyLineFormat: 'invalidPolyLineFormat',
  notEnoughPointsInPolygon: 'notEnoughPointsInPolygon',
  invalidEmailFormat: 'invalidEmailFormat',
  invalidPhoneNumberFormat: 'invalidPhoneNumberFormat',
  indexOutOfRange: 'indexOutOfRange',
  creditCardValidator: 'creditCardValidator',
  customValidator: 'customValidator',
  emailValidator: 'emailValidator',
  emptyValidator: 'emptyValidator',
  enumValidator: 'enumValidator',
  equalValidator: 'equalValidator',
  exclusiveBetweenValidator: 'exclusiveBetweenValidator',
  greaterThanOrEqualValidator: 'greaterThanOrEqualValidator',
  greaterThanValidator: 'Значення повинно бути більше 0',
  inclusiveBetweenValidator: 'inclusiveBetweenValidator',
  lengthValidator: 'lengthValidator',
  lessThanOrEqualValidator: 'lessThanOrEqualValidator',
  lessThanValidator: 'lessThanValidator',
  noopPropertyValidator: 'noopPropertyValidator',
  notEmptyValidator: "Обов'язкове поле",
  notEqualValidator: 'notEqualValidator',
  notNullValidator: 'notNullValidator',
  nullValidator: 'nullValidator',
  onFailureValidator: 'onFailureValidator',
  polymorphicValidator: 'polymorphicValidator',
  predicateValidator: 'predicateValidator',
  propertyValidator: 'propertyValidator',
  regularExpressionValidator: 'regularExpressionValidator',
  scalePrecisionValidator: 'scalePrecisionValidator',
  stringEnumValidator: 'stringEnumValidator',
  notFound: 'notFound',
  conflict: 'conflict',
  forbidden: 'forbidden',
  unauthorized: 'unauthorized',
  internalServerError: 'internalServerError',
  invalidNumberOfStandPlaces: 'invalidNumberOfStandPlaces',
  invalidNumberOfSeats: 'invalidNumberOfSeats',
  invalidNumberOfDisabilitySeats: 'invalidNumberOfDisabilitySeats'
};

export const ASSET_ERROR_MESSAGES: AssetErrorCodeMap = {
  uniqueError: 'Вказане значення уже існує',
  creditCardValidator: 'CreditCardValidator',
  customValidator: 'CustomValidator',
  emailValidator: 'EmailValidator',
  emptyValidator: 'EmptyValidator',
  enumValidator: 'EnumValidator',
  equalValidator: 'EqualValidator',
  exclusiveBetweenValidator: 'ExclusiveBetweenValidator',
  greaterThanOrEqualValidator: 'GreaterThanOrEqualValidator',
  greaterThanValidator: 'Значення повинно бути більше 0',
  inclusiveBetweenValidator: 'InclusiveBetweenValidator',
  lengthValidator: 'LengthValidator',
  lessThanOrEqualValidator: 'LessThanOrEqualValidator',
  lessThanValidator: 'LessThanValidator',
  noopPropertyValidator: 'NoopPropertyValidator',
  notEmptyValidator: "Обов'язкове поле",
  notEqualValidator: 'NotEqualValidator',
  notNullValidator: 'NotNullValidator',
  nullValidator: 'NullValidator',
  onFailureValidator: 'OnFailureValidator',
  polymorphicValidator: 'PolymorphicValidator',
  predicateValidator: 'PredicateValidator',
  propertyValidator: 'PropertyValidator',
  regularExpressionValidator: 'RegularExpressionValidator',
  scalePrecisionValidator: 'test',
  stringEnumValidator: 'StringEnumValidator',
  notFound: 'notFound',
  conflict: 'conflict',
  forbidden: 'forbidden',
  unauthorized: 'unauthorized',
  internalServerError: 'internalServerError'
};

export const amountRegExp = /^[\d]{1,4}([.][\d]{1,2})?$/;
// validation needs to cover ukrainian numbers that always have 12 chars (+38 xxx xx xx xxx)
export const phoneNumberRegExp = /^\+[\d]{12}$/g;
// stop names should only have Ukrainian letters, numbers and "'()#№,/.-
export const stopNameRegExp = /^([0-9А-ЯЄЇІа-яєїі"'()#№,/.\-\s]{1,255})?$/g;
