import { API_VERSION } from '.';

const SUB_DOMAIN = `/clearing/${API_VERSION}`;

export const KPIS = `${SUB_DOMAIN}/kpis`;

export const PENALTIES = `${SUB_DOMAIN}/penalties`;
export const PENALTIES_AUTOCOMPLETE = `${PENALTIES}/autocomplete`;

export const PENALTY_DOCUMENTS = `${SUB_DOMAIN}/penalty-documents`;

export const PENALTIES_DOCUMENT = `${SUB_DOMAIN}/penalties/penalty-documents`;

export const BILLING_DOCUMENT = `${SUB_DOMAIN}/billing`;
export const BILLING_DETAILS = `${SUB_DOMAIN}/billing-details`;
export const BILLING_DOCUMENTS_DETAILS_CARRIER = `${BILLING_DETAILS}/counteragent`;
export const BILLING_DOCUMENTS_DETAILS_ROUTE = `${BILLING_DETAILS}/routePassport`;
export const BILLING_DOCUMENTS_DETAILS_VEHICLE = `${BILLING_DETAILS}/vehicle`;

export const BILLING_DOCUMENTS_NEXT_DATE = (id: number) =>
  `${BILLING_DOCUMENT}/counteragent-document-next-date/${id}`;

export const KPI_PARAMETERS_GROUPS = `${SUB_DOMAIN}/kpi-parameters-groups`;
export const KPI_PARAMETERS = `${SUB_DOMAIN}/kpi-parameters`;
export const KPI_PARAMETERS_TOTAL_WEIGHT = `${KPI_PARAMETERS}/total-weight/`;

export const TARIFFS = `${SUB_DOMAIN}/tariff-groups`;
export const ACTIVE_TARIFFS = `${TARIFFS}/active-on-date`;

export const COEFFICIENTS_ADJUSTMENTS = `${SUB_DOMAIN}/coefficients`;
export const EDIT_COEFFICIENTS_ADJUSTMENTS = (id: number) =>
  `${COEFFICIENTS_ADJUSTMENTS}/${id}/edit`;

// inspectionDocuments
export const INSPECTION_DOCUMENTS = `${SUB_DOMAIN}/inspection-documents`;

// billingDocuments
export const BILLING_DOCUMENTS = `${SUB_DOMAIN}/billing`;
export const BILLING_DOCUMENTS_CHANGE_STATUS = (id: number, status: string) =>
  `${BILLING_DOCUMENTS}/${id}/status/${status}`;
export const BILLING_DOCUMENTS_AUTOCOMPLETE = `${BILLING_DOCUMENTS}/autocomplete`;
export const BILLING_DOCUMENTS_AUTOGENERATE = `${BILLING_DOCUMENTS}/autogenerate`;

export const BILLING_DOCUMENT_CALCULATE = (id: number) =>
  `${BILLING_DOCUMENTS}/${id}/calculate`;

export const BILLING_CALCULATOR = `${BILLING_DOCUMENTS}/calculator`;
