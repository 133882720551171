import { RootState } from 'typesafe-actions';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { Row } from 'src/components/Tables/types';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL } from 'src/config';
import { generateSendEmailAttachmentProps } from 'src/components/SendEmailModalForm/utils/generateSendEmailAttachmentProps';
import { generatePdfOfUninstallation } from '../../utils/generatePdfOfUninstallation';
import { generateJsonOfUninstallation } from '../../utils/generateJsonOfUninstallation';

export const selectListResult = (reducerPath: string) => (
  state: RootState
): Row[] => get(state, `ui.${reducerPath}.list`, []);

export const selectListCreateData = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list => {
    const res: number[] = [];

    list.forEach(el => el.isLocal && res.push(el.id));

    return res;
  });

export const selectBarcodes = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list =>
    list.map(el => el.barcode)
  );

export const selectEmailData = (
  reducerPath: string,
  mobileTablePath: string,
  stationaryTablePath: string
) => (state: RootState) => {
  const data = get(state, `ui.${reducerPath}`, {});
  const { id, documentNumber, uninstallingEmployee, uninstallationDate } = data;
  const mobileEquipment = selectListResult(mobileTablePath)(state);
  const stationaryEquipment = selectListResult(stationaryTablePath)(state);

  const date = utcToLocal(
    uninstallationDate as Date,
    DATE_FORMAT_FULL
  ) as string;

  const subject = `Демонтаж №${documentNumber} ${uninstallingEmployee?.name} ${date}`;
  const bodyText = `Здійснено демонтаж обладнання.\nДокумент демонтажу №${documentNumber} від ${date}`;
  const tags = [`uninstallation:${id}`];

  let attachments;
  if (data.id) {
    const pdfData = {
      ...data,
      mobileEquipment,
      stationaryEquipment,
      responsiblePerson: data.uninstallingEmployee.name
    };

    const pdfFile = generatePdfOfUninstallation(pdfData);
    const jsonFile = generateJsonOfUninstallation(pdfData);

    attachments = generateSendEmailAttachmentProps(subject, pdfFile, jsonFile);
  }

  return { subject, bodyText, tags, attachments };
};
