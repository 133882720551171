import moment from 'moment';
import { isInteger } from 'lodash';
import { parseCoordinates } from 'src/utils/map';
import { RouteSettingsValues } from './types';

export const isGreaterThanFourMinutes = (value: string) =>
  moment(value, 'HH:mm').isAfter(moment('00:04', 'HH:mm'));

export const formatRouteSettings = ({
  routeDeviation,
  ...settings
}: RouteSettingsValues) => {
  const topLeftPoint = parseCoordinates(settings.topLeftPoint);
  const bottomRightPoint = parseCoordinates(settings.bottomRightPoint);

  return {
    ...settings,
    routeDeviation: {
      ...routeDeviation,
      noDeviationDelay: -routeDeviation.noDeviationDelay,
      lowDeviationDelay: -routeDeviation.lowDeviationDelay
    },
    mapConfiguration: {
      topLeftLat: topLeftPoint[0],
      topLeftLng: topLeftPoint[1],
      bottomRightLat: bottomRightPoint[0],
      bottomRightLng: bottomRightPoint[1]
    }
  };
};

export const parseTimeInputValue = (val: string | number) => {
  const num = Number(val);

  if (!isInteger(num)) {
    return '';
  }

  if (num < 10) {
    return `0${num}`;
  }

  return `${num}`;
};
