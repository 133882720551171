import { createAsyncAction } from 'typesafe-actions';

export const updateInspection = createAsyncAction(
  '@INSPECTIONS/UPDATE_INSPECTION_REQUEST',
  '@INSPECTIONS/UPDATE_INSPECTION_SUCCESS',
  '@INSPECTIONS/UPDATE_INSPECTION_FAILURE'
)<string, undefined, Error>();

export const getInspection = createAsyncAction(
  '@INSPECTIONS/GET_INSPECTION_REQUEST',
  '@INSPECTIONS/GET_INSPECTION_SUCCESS',
  '@INSPECTIONS/GET_INSPECTION_FAILURE'
)<undefined, undefined, Error>();
