import { PdfGenerator, TypeTable } from 'src/services/pdfGenerator';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL } from 'src/config';
import {
  uninstallationDocumentPdf,
  uninstallationMobileColumnsPdf,
  uninstallationStationaryColumnsPdf
} from '../const';
import { UninstallationPayload } from '../UninstallationDetailsView/store/types';

export function generatePdfOfUninstallation(
  data: UninstallationPayload
): PdfGenerator {
  const {
    documentNumber,
    mobileEquipment,
    stationaryEquipment,
    uninstallationDate,
    uninstallingEmployeeName
  } = data;

  const dateTitle = utcToLocal(uninstallationDate as Date, DATE_FORMAT_FULL);
  const dateSubject = utcToLocal(uninstallationDate as Date, DATE_FORMAT_FULL);

  const title = `Демонтаж №${documentNumber} ${uninstallingEmployeeName} ${dateTitle}`;
  const subject = `Акт виконаних робіт № ${documentNumber} від ${dateSubject}`;

  return new PdfGenerator(title, 13, { orientation: 'landscape' })
    .setHeader(subject, true)
    .setTable(uninstallationDocumentPdf, data, TypeTable.InfoTable)
    .setHeader('Демонтоване мобільне обладнання', false)
    .setTable(
      uninstallationMobileColumnsPdf,
      mobileEquipment,
      TypeTable.ListTable
    )
    .setHeader('Демонтоване стаціонарне обладнання', false)
    .setTable(
      uninstallationStationaryColumnsPdf,
      stationaryEquipment,
      TypeTable.ListTable
    );
}
