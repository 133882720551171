import { createAsyncAction } from 'typesafe-actions';

interface ValidationPayload {
  barcode: string;
  imei: string;
  equipmentId: number;
}

export const validateEquipment = createAsyncAction(
  '@INSTALLATION/VALIDATE_EQUIPMENT_REQUEST',
  '@INSTALLATION/VALIDATE_EQUIPMENT_SUCCESS',
  '@INSTALLATION/VALIDATE_EQUIPMENT_FAILURE'
)<ValidationPayload, undefined, Error>();
