import {
  EQUIPMENT_GROUP_NAMES,
  REGISTERED_EMPLOYEE_AUTOCOMPLETE
} from 'src/config/assetUrls';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';

export const columns = [
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Дата реєстрації',
    value: 'registrationDate',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Хто зареєстрував',
    value: 'registeredByEmployeeName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Група обладнання',
    value: 'equipmentGroupName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Кількість обладнання',
    value: 'amountOfEquipment',
    sortable: true,
    width: '20%'
  }
];

export const BREAKDOWNS_TABLE_NAME = 'BREAKDOWNS';
export const BREAKDOWNS_TABLE_PATH = 'asset.breakdowns.table';

export const REGISTERED_EMPLOYEE_NAME = 'REGISTERED_EMPLOYEE_NAME';

export const filters = [
  {
    type: FilterType.Date,
    name: 'registrationDate',
    label: 'Дата',
    datePickerProps: {
      disableFuture: true
    }
  },
  {
    name: 'registeredByEmployeeId',
    label: 'Хто зареєстрував',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: REGISTERED_EMPLOYEE_NAME,
      reducerPath: 'asset.breakdowns.registeredEmployeeAutocomplete',
      optionsApiUrl: REGISTERED_EMPLOYEE_AUTOCOMPLETE
    }
  },
  {
    name: 'equipmentGroupId',
    label: 'Група обладнання',
    optionsURL: EQUIPMENT_GROUP_NAMES
  }
];
