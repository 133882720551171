import { SagaIterator } from 'redux-saga';
import { takeEvery, call, select, put } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { omit } from 'lodash';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { StatusEnum } from 'src/constants';
import { UNINSTALLATIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import { setFormValues } from '../../UninstallationStep/store/actions';
import { FormValues } from '../../UninstallationStep/store/types';
import { selectListCreateData } from '../../UninstallationEquipmentsStep/store/selectors';
import { selectFormCreateData } from '../../UninstallationStep/store/selectors';

export function* createDraftUninstallation({
  payload
}: ReturnType<typeof actions.createDraftUninstallation.request>): SagaIterator {
  const { formConfig, mobileTablePath, stationaryTablePath } = payload;
  const formData = yield select(selectFormCreateData(formConfig.reducerPath));
  const mobileEquipments = yield select(selectListCreateData(mobileTablePath));
  const stationaryEquipments = yield select(
    selectListCreateData(stationaryTablePath)
  );

  const { data } = yield call(
    apiClient.post,
    UNINSTALLATIONS,
    {
      ...formData,
      equipmentIds: [...mobileEquipments, ...stationaryEquipments],
      status: StatusEnum.Draft
    },
    {
      retryAction: actions.createDraftUninstallation.request(payload)
    }
  );
  yield put(setFormValues(formConfig, omit(data as FormValues, 'equipments')));
  yield put(actions.createDraftUninstallation.success());
}

export default function* UninstallationCreateView(): SagaIterator {
  yield takeEvery(
    getType(actions.createDraftUninstallation.request),
    safe(createDraftUninstallation, actions.createDraftUninstallation.failure)
  );
}
