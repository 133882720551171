import { EquipmentTypeEnum, equipmentTypes } from 'src/constants';
import { EQUIPMENT_GROUP_NAMES } from 'src/config/assetUrls';
import { COUNTERAGENTS, EMPLOYEES } from 'src/config/avlUrls';
import {
  Column,
  FilterType
} from 'src/components/Tables/TableWithPagination/types';
import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';

export const columns: Column[] = [
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Дата',
    value: 'inspectionDate',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragentName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Група обладнання',
    value: 'equipmentGroupId',
    sortable: true,
    width: '15%',
    changeValue: row =>
      equipmentTypes[row.equipmentGroupId as EquipmentTypeEnum]
  },
  {
    label: 'Інспектор',
    value: 'responsiblePerson',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Статус документу',
    value: 'status',
    sortable: true,
    width: '15%',
    changeValue: changeStatusColumn
  }
];

export const INSPECTIONS_TABLE_NAME = 'INSPECTIONS';
export const INSPECTIONS_TABLE_PATH = 'asset.inspections.table';

export const filters = [
  {
    type: FilterType.Date,
    name: 'inspectionDate',
    label: 'Дата',
    datePickerProps: {
      disableFuture: true
    }
  },
  {
    name: 'counteragentId',
    label: 'Підрозділ',
    optionsURL: COUNTERAGENTS
  },
  {
    name: 'equipmentGroupId',
    label: 'Група обладнання',
    optionsURL: EQUIPMENT_GROUP_NAMES
  },
  {
    name: 'responsiblePersonId',
    label: 'Інспектор',
    optionsURL: EMPLOYEES
  }
];
