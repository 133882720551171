import { SagaIterator } from 'redux-saga';
import { put, call, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { INSTALLATIONS_EQUIPMENT_VALIDATION } from 'src/config/assetUrls';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';

export function* validateEquipment({
  payload
}: ReturnType<typeof actions.validateEquipment.request>): SagaIterator {
  yield call(apiClient.post, INSTALLATIONS_EQUIPMENT_VALIDATION, payload, {
    retryAction: actions.validateEquipment.request(payload)
  });
  yield put(actions.validateEquipment.success());
}

export default function* EquipmentStepSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.validateEquipment.request),
    safe(validateEquipment, actions.validateEquipment.failure)
  );
}
