import { createCustomAction } from 'typesafe-actions';
import {
  GetDataRequestPayload,
  GetDataSuccessPayload,
  TableConfig,
  SelectedPayload,
  GetFilterOptionsRequestPayload,
  GetFilterOptionsSuccessPayload
} from '../types';

export const getDataRequest = createCustomAction(
  '@TABLE_WITH_PAGINATION/GET_DATA_REQUEST',
  (config: TableConfig, payload: GetDataRequestPayload) => ({ payload, config })
);

export const getDataSuccess = createCustomAction(
  '@TABLE_WITH_PAGINATION/GET_DATA_SUCCESS',
  (config: TableConfig, payload: GetDataSuccessPayload) => ({
    payload,
    config
  })
);

export const getDataFailure = createCustomAction(
  '@TABLE_WITH_PAGINATION/GET_DATA_FAILURE',
  (config: TableConfig, payload: Error) => ({ payload, config })
);

export const setSelectAll = createCustomAction(
  '@TABLE_WITH_PAGINATION/SET_SELECT_ALL',
  (config: TableConfig, payload: boolean) => ({ payload, config })
);

export const setSelected = createCustomAction(
  '@TABLE_WITH_PAGINATION/SET_SELECTED',
  (config: TableConfig, payload: SelectedPayload) => ({
    payload,
    config
  })
);

export const deleteRowRequest = createCustomAction(
  '@TABLE_WITH_PAGINATION/DELETE_ROW_REQUEST',
  (config: TableConfig, payload: { id: number; name: string }) => ({
    payload,
    config
  })
);

export const deleteRowSuccess = createCustomAction(
  '@TABLE_WITH_PAGINATION/DELETE_ROW_SUCCESS',
  (config: TableConfig) => ({
    config
  })
);

export const deleteRowFailure = createCustomAction(
  '@TABLE_WITH_PAGINATION/DELETE_ROW_FAILURE',
  (config: TableConfig, payload?: Error) => ({ payload, config })
);

export const deleteSelectedRowsRequest = createCustomAction(
  '@TABLE_WITH_PAGINATION/DELETE_SELECTED_ROWS_REQUEST',
  (config: TableConfig) => ({ config })
);

export const deleteSelectedRowsSuccess = createCustomAction(
  '@TABLE_WITH_PAGINATION/DELETE_SELECTED_ROWS_SUCCESS',
  (config: TableConfig) => ({
    config
  })
);

export const deleteSelectedRowsFailure = createCustomAction(
  '@TABLE_WITH_PAGINATION/DELETE_SELECTED_ROWS_FAILURE',
  (config: TableConfig, payload: Error) => ({ payload, config })
);

export const getFilterOptionsRequest = createCustomAction(
  '@TABLE_WITH_PAGINATION/GET_FILTER_OPTIONS_REQUEST',
  (config: TableConfig, payload: GetFilterOptionsRequestPayload) => ({
    payload,
    config
  })
);

export const getFilterOptionsSuccess = createCustomAction(
  '@TABLE_WITH_PAGINATION/GET_FILTER_OPTIONS_SUCCESS',
  (config: TableConfig, payload: GetFilterOptionsSuccessPayload) => ({
    payload,
    config
  })
);

export const getFilterOptionsFailure = createCustomAction(
  '@TABLE_WITH_PAGINATION/GET_FILTER_OPTIONS_FAILURE',
  (config: TableConfig, payload: Error) => ({ payload, config })
);

export const tableUnmount = createCustomAction(
  '@TABLE_WITH_PAGINATION/TABLE_UNMOUNT',
  (config: TableConfig) => ({ config })
);

export const clearTableWithPaginationState = createCustomAction(
  '@TABLE_WITH_PAGINATION/CLEAR_TABLE_STATE',
  (config: TableConfig) => ({ config })
);
