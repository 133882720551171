import { SagaIterator } from 'redux-saga';
import { takeEvery, call, select, put, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { navigateTo } from 'src/store/actions/app';
import { INSPECTIONS } from 'src/config/assetUrls';
import { INSPECTIONS_URL } from 'src/routes/assetRoutes';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import {
  CREATE_INSPECTION_FORM_PATH,
  INSPECTIONS_EQUIPMENT_PATH
} from '../const';
import { selectFormCreateData } from '../../InspectionFormStep/store/selectors';
import { selectListCreateData } from '../../InspectionsEquipmentStep/store/selectors';

export function* createInspection({
  payload: status
}: ReturnType<typeof actions.createInspection.request>): SagaIterator {
  const [formData, equipment] = yield all([
    select(selectFormCreateData(CREATE_INSPECTION_FORM_PATH)),
    select(selectListCreateData(INSPECTIONS_EQUIPMENT_PATH))
  ]);

  yield call(apiClient.post, INSPECTIONS, {
    status,
    equipment,
    ...formData
  });
  yield put(navigateTo({ url: INSPECTIONS_URL }));
  yield put(
    enqueueSnackbar({
      key: 'inspection_created_success',
      message: status === StatusEnum.Draft ? 'Чернетку збережено' : 'Документ збережено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.createInspection.success());
}

export default function* InspectionsCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createInspection.request),
    safe(createInspection, actions.createInspection.failure)
  );
}
