import { date, object, number, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import { EMPLOYEES_AUTOCOMPLETE } from 'src/config/avlUrls';
import { isToday } from 'date-fns';

export const UNINSTALLING_EMPLOYEES_NAME =
  'UNINSTALLING_EMPLOYEES_AUTOCOMPLETE';
export const UNINSTALLING_EMPLOYEES_PATH =
  'asset.uninstallation.uninstallingEmployeesAutocomplete';

export const UNINSTALLING_EMPLOYEE_CONFIG = {
  optionsApiUrl: EMPLOYEES_AUTOCOMPLETE,
  reducerName: UNINSTALLING_EMPLOYEES_NAME,
  reducerPath: UNINSTALLING_EMPLOYEES_PATH
};

const uninstallationDate = date()
  .required(ERROR_MESSAGES.REQUIRED)
  .test({ message: ERROR_MESSAGES.TYPE_ERROR, test: isToday })
  .typeError(ERROR_MESSAGES.TYPE_ERROR)
  .nullable();
const notes = string().max(...maxLength(255));

export const uninstallationValidationSchema = object().shape({
  uninstallationDate,
  uninstallingEmployee: number(),
  notes
});

export const uninstallationDraftValidationSchema = object().shape({
  uninstallationDate,
  uninstallingEmployee: object()
    .shape({
      id: number()
    })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  notes
});
