import { Row } from 'src/components/Tables/types';
import EquipmentStateLabel from 'src/components/Labels/EquipmentStateLabel';

export const mobileColumns = [
  {
    label: 'Транспортний засіб',
    value: 'licensePlate',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragentName',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Назва обладнання',
    value: 'name',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '15%'
  },
  {
    label: 'IMEI',
    value: 'imei',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Стан обладнання',
    value: 'status',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => <EquipmentStateLabel state={row.status} />
  }
];

export const stationaryColumns = [
  {
    label: 'Підрозділ',
    value: 'counteragentName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Адреса',
    value: 'address',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Відповідальна особа',
    value: 'employeeName',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Назва обладнання',
    value: 'name',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '15%'
  },
  {
    label: 'IMEI',
    value: 'imei',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Стан обладнання',
    value: 'status',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => <EquipmentStateLabel state={row.status} />
  }
];

export const INSTALLATION_EQUIPMENT_TABLE_PATH =
  'asset.installation.create.equipment';
export const INSTALLATION_EQUIPMENT_TABLE_NAME = 'EQUIPMENT';

export const tableConfig = {
  reducerName: INSTALLATION_EQUIPMENT_TABLE_NAME,
  reducerPath: INSTALLATION_EQUIPMENT_TABLE_PATH,
  routeUrl: '/asset/installation',
  rowActions: {},
  toolbarActions: {},
  filters: [],
  isRowClickEnabled: false,
  snackbarMessages: {
    deleteRowSuccess: ''
  }
};
