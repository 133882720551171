import { createReducer, ActionType } from 'typesafe-actions';
import moment from 'moment';
import { DATEPICKER_DATE_FORMAT } from 'src/config';
import { utcToLocal } from 'src/utils/date';
import * as actions from './actions';
import { BreakdownsStepViewConfig } from './types';
import { EquipmentTypeEnum } from '../../const';

export const initialState: BreakdownsStepViewConfig = {
  documentId: '',
  installDate: moment().format(DATEPICKER_DATE_FORMAT),
  installingEmployee: '',
  acceptingEmployee: null,
  equipmentType: EquipmentTypeEnum.Mobile,
  counteragent: null,
  counteragentId: null,
  vehicleId: null,
  address: '',
  vehicleNumbers: null,
  vehicleModelName: '',
  vehicleType: '',
  notes: '',
  breakdownItems: []
};

const breakdownStepReducer = createReducer<
  BreakdownsStepViewConfig,
  ActionType<typeof actions>
>(initialState)
  .handleAction(actions.getCounteragentSuccess, (state, { payload }) => ({
    ...state,
    address: payload.address
  }))
  .handleAction(actions.getVehicleModelSuccess, (state, { payload }) => ({
    ...state,
    vehicleModelName: payload.vehicleModelName
  }))
  .handleAction(actions.getVehicleTypeSuccess, (state, { payload }) => ({
    ...state,
    vehicleType: payload.vehicleType
  }))
  .handleAction(actions.setFormValues, (state, { values }) => ({
    ...state,
    ...values
  }))
  .handleAction(actions.getBreakdownDataSuccess, (_, { payload }) => {
    const parseToAutocompleteValue = (name?: string, id = 0) =>
      name ? { name, id } : null;

    return {
      documentId: `${payload.documentNumber}`,
      installDate: utcToLocal(payload.registrationDate, DATEPICKER_DATE_FORMAT),
      installingEmployee: payload.reportingEmployeeName,
      acceptingEmployee: parseToAutocompleteValue(
        payload.registrationEmployeeName,
        payload.registeredByEmployeeId
      ),
      equipmentType: payload.equipmentGroupId,
      counteragent: parseToAutocompleteValue(payload.counteragentName),
      counteragentId: payload.counteragentId,
      vehicleId: payload.vehicleId,
      address: payload.counteragentAddress,
      vehicleNumbers: parseToAutocompleteValue(payload.licensePlate),
      vehicleModelName: payload.vehicleModelName,
      vehicleType: payload.vehicleType,
      notes: payload.notes,
      breakdownItems: payload.breakdownItems
    };
  });

export default breakdownStepReducer;
