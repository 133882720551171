import { Row } from 'src/components/Tables/types';

export const normalizeBreakdownData = (data: Row) => {
  const counteragentID = data?.counteragentId;
  return data?.map((item: Row) => ({
    breakdown: item.breakdownTypeName,
    equipment: item.equipmentDetails.name,
    breakdownTypeId: item.breakdownTypeId,
    state: item.equipmentDetails.state,
    id: item.installationEquipmentId,
    barCode: item.equipmentDetails.barcode,
    name: item.equipmentDetails.name,
    productType: item.equipmentDetails.productType,
    installationVehicleId: item.id,
    installationCountragentId: counteragentID,
    equipmentStatusName: item.equipmentDetails.state
  }));
};
