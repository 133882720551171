import { createSelector } from 'reselect';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { EquipmentTypeEnum, equipmentTypes, StatusEnum } from 'src/constants';
import { convertUtcToLocal } from 'src/utils/date';
import { DATE_FORMAT_SLASH } from 'src/config';
import { Row } from 'src/components/Tables/types';
import { BreakdownDetailRows, BreakdownDetailsState } from '../types';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/asset/breakdowns/:id/details'
);

export const selectBreakdownRowsForDetails = (
  state: RootState
): BreakdownDetailRows[] => {
  const rows = state.ui.asset.breakdowns.details.breakdownItems;
  return rows.map((item: Row) => ({
    ...item.equipmentDetails,
    ...item
  }));
};

export const selectBreakdownDetails = (
  state: RootState
): BreakdownDetailsState => state.ui.asset.breakdowns.details;

export const selectCharacteristicRows = (
  state: RootState
): Array<{ label: string; value: string | number }> => {
  const { equipmentGroupId, ...breakdowns } = get(
    state,
    `ui.asset.breakdowns.details`,
    {}
  );

  const mobileItems = [
    { label: 'Тип ТЗ', value: breakdowns.vehicleCategoryName },
    { label: 'Номерний знак ТЗ', value: breakdowns.licensePlate },
    { label: 'Модель', value: breakdowns.vehicleModelName }
  ];

  const nonMobileItems = [
    { label: 'Адреса підрозділу', value: breakdowns.counteragentAddress }
  ];

  return [
    { label: 'Номер документа', value: breakdowns.documentNumber },
    {
      label: 'Дата',
      value: convertUtcToLocal(breakdowns.registrationDate, DATE_FORMAT_SLASH)
    },
    { label: 'Хто прийняв', value: breakdowns.registrationEmployeeName },
    { label: 'Хто заявив', value: breakdowns.reportingEmployeeName },
    {
      label: 'Група обладнання',
      value: equipmentTypes[equipmentGroupId as EquipmentTypeEnum]
    },
    { label: 'Підрозділ', value: breakdowns.counteragentName }
  ].concat(
    equipmentGroupId === EquipmentTypeEnum.Mobile
      ? mobileItems
      : nonMobileItems,
    {
      label: 'Примітка',
      value: breakdowns.notes || '-'
    }
  );
};

export const selectIsLoadingInstallation = (state: RootState): boolean => {
  const loading = get(state, `loading.@BREAKDOWN_DETAILS/GET_BREAKDOWN`);

  return isBoolean(loading) ? loading : true;
};

export const selectBreakdownId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectIsEditDisabled = (state: RootState) =>
  get(state, `ui.asset.breakdowns.details.installationStatusId`, {}) !==
  StatusEnum.Draft;

export const selectDocumentNumber = createSelector(
  selectBreakdownDetails,
  ({ documentNumber }) => documentNumber
);
