import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { UNINSTALLATIONS } from 'src/config/assetUrls';
import { getDataRequest } from 'src/components/Tables/TableWithPagination/store/actions';
import * as tableSelectors from 'src/components/Tables/TableWithPagination/store/selectors';
import * as actions from './actions';

export function* updateTableItemState({
  payload
}: ReturnType<typeof actions.updateTableItemStatus.request>): SagaIterator {
  const { config, id } = payload;
  const [selectList, selectSorters, selectFilters] = yield all([
    call(tableSelectors.selectList, config.reducerPath),
    call(tableSelectors.selectSorters, config.reducerPath),
    call(tableSelectors.selectFilters, config.reducerPath)
  ]);
  const [{ currentPage, pageSize }, sorters, filters] = yield all([
    select(selectList),
    select(selectSorters),
    select(selectFilters)
  ]);

  yield call(
    apiClient.put,
    `${UNINSTALLATIONS}/${id}/change-status`,
    { statusId: 0 },
    { retryAction: actions.updateTableItemStatus.request(payload) }
  );
  yield put(
    getDataRequest(config as any, {
      page: currentPage,
      pageSize,
      sorters,
      filters,
      resetSelection: true
    })
  );
  yield put(actions.updateTableItemStatus.success());
}

export default function* UninstallationListViewSaga() {
  yield takeEvery(
    getType(actions.updateTableItemStatus.request),
    safe(updateTableItemState, actions.updateTableItemStatus.failure)
  );
}
