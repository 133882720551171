import { all, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import TableSagas from 'src/components/Tables/TableWithPagination/store/sagas';
import LocalTableSaga from 'src/components/Tables/Table/store/sagas';
import KPICreateSaga from 'src/views/clearing/kpis/KpiCreateView/store/sagas';
import KPIEditSaga from 'src/views/clearing/kpis/KpiEditView/store/sagas';
import PenaltyCreateSaga from 'src/views/clearing/penalties/PenaltyCreateView/store/sagas';
import PenaltyEditSaga from 'src/views/clearing/penalties/PenaltyEditView/store/sagas';
import KPIParametersCreateSaga from 'src/views/clearing/kpiParameters/KpiParametersCreateView/store/sagas';
import KPIParametersEditSaga from 'src/views/clearing/kpiParameters/KpiParametersEditView/store/sagas';
import KPIParametersFormSaga from 'src/views/clearing/kpiParameters/KpiParametersForm/store/sagas';
import KpiParametersDetailsSaga from 'src/views/clearing/kpiParameters/KpiParametersDetailsView/store/sagas';
import TariffCreateSaga from 'src/views/clearing/tariffs/TariffCreateView/store/sagas';
import TariffDetailsSaga from 'src/views/clearing/tariffs/TariffDetailsView/store/sagas';
import InspectionDocumentsDetailsSaga from 'src/views/clearing/inspectionDocuments/InspectionDocumentDetailsView/store/sagas';
import InspectionDocumentsCreateSaga from 'src/views/clearing/inspectionDocuments/InspectionDocumentsCreateView/store/sagas';
import InspectionDocumentsEditSaga from 'src/views/clearing/inspectionDocuments/InspectionDocumentsEditView/store/sagas';
import StopCreateSaga from 'src/views/avl/stops/StopsCreateView/store/sagas';
import StopEditSaga from 'src/views/avl/stops/StopsEditView/store/sagas';
import StopDetailsSaga from 'src/views/avl/stops/StopsDetailsView/store/sagas';
import EmployeeEditSaga from 'src/views/avl/employees/EmployeeEditView/store/sagas';
import VehicleModelsDetailsSaga from 'src/views/avl/vehiclesModels/VehiclesModelDetailsView/store/sagas';
import EditableSelectSaga from 'src/components/EditableSelect/store/sagas';
import SelectSaga from 'src/components/Select/store/sagas';
import AutocompleteSaga from 'src/components/Form/FormAutocomplete/store/sagas';
import TableViewSaga from 'src/components/Tables/TableView/store/sagas';
import SendEmailSaga from 'src/components/SendEmailModalForm/store/sagas';
import InspectionDetailsSaga from 'src/views/asset/inspections/InspectionDetailsView/store/sagas';
import InspectionsCreateSaga from 'src/views/asset/inspections/InspectionsCreateView/store/sagas';
import InspectionEditSaga from 'src/views/asset/inspections/InspectionEditView/store/sagas';
import DownloadReportsSaga from 'src/views/avl/reports/ReportsDownloadView/store/sagas';
import CoefficientsAdjustmentsEditSaga from 'src/views/clearing/coefficientsAdjustments/CoefficientsAdjustmentsEditView/store/sagas';
import CoefficientsAdjustmentsCreateSaga from 'src/views/clearing/coefficientsAdjustments/CoefficientsAdjustmentsCreateView/store/sagas';
import { SchedulesStatusSaga } from 'src/views/avl/dispatching/schedulesStatus/store/sagas';
import { TrafficMapSagas } from 'src/views/avl/dispatching/trafficMap/store/sagas';
import AppSagas from './sagas/appSagas';
import InstallationEditSaga from '../views/asset/installation/InstallationEditView/store/sagas';
import InstallationStepSaga from '../views/asset/installation/InstallationCreateView/InstallationStepView/store/sagas';
import BreakdownStepSaga from '../views/asset/breakdowns/BreakdownsCreateView/BreakdownsStepView/store/sagas';
import InstallationCreateSaga from '../views/asset/installation/InstallationCreateView/store/sagas';
import ConfirmInstallationSaga from '../views/asset/installation/InstallationCreateView/ConfirmStepView/store/sagas';
import UninstallationCreateViewSaga from '../views/asset/uninstallation/UninstallationCreateView/store/sagas';
import UninstallationEditViewSaga from '../views/asset/uninstallation/UninstallationEditView/store/sagas';
import UninstallationEquipmentsStepSaga from '../views/asset/uninstallation/UninstallationEquipmentsStep/store/sagas';
import UninstallationDetailsSaga from '../views/asset/uninstallation/UninstallationDetailsView/store/sagas';
import UninstallationListViewSaga from '../views/asset/uninstallation/UninstallationListView/store/sagas';
import BreakdownsTypesCreateSaga from '../views/asset/breakdownsTypes/BreakdownsTypesCreateView/store/sagas';
import BreakdownsTypesEditSaga from '../views/asset/breakdownsTypes/BreakdownsTypesEditView/store/sagas';
import EmployeeCreateSaga from '../views/avl/employees/EmployeeCreateView/store/sagas';
import EmployeeDetailsSaga from '../views/avl/employees/EmployeeDetailsView/store/sagas';
import VehiclesModelCreateSaga from '../views/avl/vehiclesModels/VehiclesModelCreateView/store/sagas';
import VehiclesModelEditSaga from '../views/avl/vehiclesModels/VehiclesModelEditView/store/sagas';
import VehiclesCreateSaga from '../views/avl/vehicles/VehiclesCreateView/store/sagas';
import VehiclesEditSaga from '../views/avl/vehicles/VehiclesEditView/store/sagas';
import DangerousZoneCreateSaga from '../views/avl/dangerousZones/DangerousZonesCreateView/store/sagas';
import DangerousZoneEditSaga from '../views/avl/dangerousZones/DangerousZonesEditView/store/sagas';
import DangerousZoneDetailsSaga from '../views/avl/dangerousZones/DangerousZonesDetailsView/store/sagas';
import DetailsActionsSaga from '../components/DetailsActions/store/sagas';
import TariffEditSaga from '../views/clearing/tariffs/TariffEditView/store/sagas';
import CounterAgentCreateSaga from '../views/avl/counteragents/CounteragentsCreateView/store/sagas';
import CounterAgentDetailsSaga from '../views/avl/counteragents/CounteragentsDetailsView/store/sagas';
import VehicleDetailsSaga from '../views/avl/vehicles/VehicleDetailsView/store/sagas';
import EquipmentCreateSaga from '../views/asset/equipment/EquipmentCreateView/store/sagas';
import CounterAgentEditSaga from '../views/avl/counteragents/CounteragentsEditView/store/sagas';
import BreakdownsTypesDetailsSaga from '../views/asset/breakdownsTypes/BreakdownsTypesDetailsView/store/sagas';
import EquipmentEditSaga from '../views/asset/equipment/EquipmentEditView/store/sagas';
import EquipmentDetailsSaga from '../views/asset/equipment/EquipmentDetailsView/store/sagas';
import EquipmentStepSaga from '../views/asset/installation/InstallationCreateView/EquipmentStepView/store/sagas';
import PassportCreateSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/Settings/SettingsCreateView/store/sagas';
import RouteSchemeCreateSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/RouteSchemeCreateView/store/sagas';
import PassportSettingsDetailsSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/Settings/SettingsDetailsView/store/sagas';
import TimetableCreateSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/Timetable/TimetableCreateView/store/sagas';
import RouteSchemeDetailsViewSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/store/sagas';
import CreateDepartureSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/Timetable/Departures/DepartureCreateView/store/sagas';
import InstallationListViewSaga from '../views/asset/installation/InstallationListView/store/sagas';
import InstallationDetailsSaga from '../views/asset/installation/InstallationDetailsView/store/sagas';
import TimetableEditSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/Timetable/TimetableEditView/store/sagas';
import IterationsSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/Timetable/Departures/DeparturesForm/IterationsForm/store/sagas';
import RouteSchemeUpdateSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/RouteSchemeEditView/store/sagas';
import PenaltyDocumentCreateSaga from '../views/clearing/penaltyDocuments/PenaltyDocumentCreateView/store/sagas';
import PenaltyDocumentEditSaga from '../views/clearing/penaltyDocuments/PenaltyDocumentEditView/store/sagas';
import DepartureDetailsSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/Timetable/Departures/DepartureDetailsView/store/sagas';
import DepartureEditSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/Timetable/Departures/DepartureEditView/store/sagas';
import TrafficStadiumSaga from '../views/avl/dispatching/trafficStadium/store/sagas';
import EquipmentBreakdownStepSaga from '../views/asset/breakdowns/BreakdownsCreateView/EquipmentStepView/store/sagas';
import BreakdownCreateSaga from '../views/asset/breakdowns/BreakdownsCreateView/store/sagas';
import SchedulesSaga from '../views/avl/dispatching/schedulePlanning/store/sagas';
import BreakdownDetailsSaga from '../views/asset/breakdowns/BreakdownDetailsView/store/sagas';
import BreakdownEditSaga from '../views/asset/breakdowns/BreakdownsEditView/store/sagas';
import RouteSettingsSaga from '../views/administration/routeSettings/store/sagas';
import OtherSettingsSaga from '../views/administration/otherSettings/store/sagas';
import BillingDocumentCreateSaga from '../views/clearing/billingDocuments/BillingDocumentCreateView/store/sagas';
import coefficientAdjustmentsDetailsSaga from '../views/clearing/coefficientsAdjustments/CoefficientsAdjustmentsDetailsView/store/sagas';
import BillingDocumentDetailsSaga from '../views/clearing/billingDocuments/BillingDocumentsDetailsView/store/sagas';
import BillingListViewSaga from '../views/clearing/billingDocuments/BillingDocumentsListView/store/sagas';
import BillingDocumentEditSaga from '../views/clearing/billingDocuments/BillingDocumentsEditView/store/sagas';
import RouteSchemeSummarySaga from '../views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/RouteSchemeSummary/store/sagas';
import userEditSaga from '../views/administration/usersSettings/components/UserEditView/store/sagas';
import InspectionListSaga from '../views/asset/inspections/InspectionsListView/store/sagas';
import PaymentsSettingsSaga from '../views/administration/paymentsSettings/store/sagas';
import BreakdownListSaga from '../views/asset/breakdowns/BreakdownsListView/store/sagas';
import BillingDetailsListSaga from '../views/clearing/billingDocuments/BillingDetailsListView/store/sagas';
import DownloadAssetReportsSaga from '../views/asset/reports/ReportsDownloadView/store/sagas';
import RoutesTimetableSaga from '../views/avl/Routes/RoutesPassportLayout/Steps/Timetable/store/sagas';
import RoutesPassportPdf from '../views/avl/Routes/RoutesListView/store/sagas';
import UsersLogsSaga from '../views/administration/usersLogs/store/sagas';
import ApiKeysSaga from '../views/administration/apiKeysSettings/store/sagas';

/* '
  connection of all sagas goes here
  sagas should be placed under '/pages/pageName/store/sagas.ts
 */

function* rootSaga(): SagaIterator {
  yield all([
    call(TableSagas),
    call(LocalTableSaga),
    call(KPICreateSaga),
    call(KPIEditSaga),
    call(PenaltyCreateSaga),
    call(PenaltyEditSaga),
    call(KPIParametersCreateSaga),
    call(KPIParametersEditSaga),
    call(KPIParametersFormSaga),
    call(TariffCreateSaga),
    call(TariffDetailsSaga),
    call(InspectionDocumentsDetailsSaga),
    call(InspectionDocumentsCreateSaga),
    call(InspectionDocumentsEditSaga),
    call(StopCreateSaga),
    call(StopEditSaga),
    call(StopDetailsSaga),
    call(EditableSelectSaga),
    call(InstallationEditSaga),
    call(InstallationStepSaga),
    call(BreakdownCreateSaga),
    call(BreakdownStepSaga),
    call(BreakdownDetailsSaga),
    call(BreakdownEditSaga),
    call(BreakdownListSaga),
    call(InstallationCreateSaga),
    call(UninstallationCreateViewSaga),
    call(UninstallationEditViewSaga),
    call(UninstallationEquipmentsStepSaga),
    call(UninstallationDetailsSaga),
    call(UninstallationListViewSaga),
    call(BreakdownsTypesCreateSaga),
    call(BreakdownsTypesEditSaga),
    call(EmployeeCreateSaga),
    call(EmployeeDetailsSaga),
    call(EmployeeEditSaga),
    call(DangerousZoneCreateSaga),
    call(AppSagas),
    call(DangerousZoneDetailsSaga),
    call(DetailsActionsSaga),
    call(DangerousZoneEditSaga),
    call(TariffEditSaga),
    call(CounterAgentCreateSaga),
    call(CounterAgentDetailsSaga),
    call(SelectSaga),
    call(AutocompleteSaga),
    call(VehiclesModelCreateSaga),
    call(VehicleModelsDetailsSaga),
    call(VehicleDetailsSaga),
    call(VehiclesModelEditSaga),
    call(EquipmentCreateSaga),
    call(EquipmentEditSaga),
    call(EquipmentStepSaga),
    call(EquipmentBreakdownStepSaga),
    call(VehiclesCreateSaga),
    call(VehiclesEditSaga),
    call(CounterAgentEditSaga),
    call(BreakdownsTypesDetailsSaga),
    call(PassportCreateSaga),
    call(RouteSchemeCreateSaga),
    call(PassportSettingsDetailsSaga),
    call(TimetableCreateSaga),
    call(RouteSchemeDetailsViewSaga),
    call(CreateDepartureSaga),
    call(SendEmailSaga),
    call(TableViewSaga),
    call(InstallationListViewSaga),
    call(ConfirmInstallationSaga),
    call(EquipmentDetailsSaga),
    call(InstallationDetailsSaga),
    call(TimetableEditSaga),
    call(IterationsSaga),
    call(PenaltyDocumentCreateSaga),
    call(PenaltyDocumentEditSaga),
    call(DepartureDetailsSaga),
    call(DepartureEditSaga),
    call(RouteSchemeUpdateSaga),
    call(TrafficStadiumSaga),
    call(SchedulesSaga),
    call(InspectionDetailsSaga),
    call(InspectionsCreateSaga),
    call(InspectionEditSaga),
    call(KpiParametersDetailsSaga),
    call(RouteSettingsSaga),
    call(CoefficientsAdjustmentsEditSaga),
    call(CoefficientsAdjustmentsCreateSaga),
    call(coefficientAdjustmentsDetailsSaga),
    call(OtherSettingsSaga),
    call(BillingDocumentCreateSaga),
    call(BillingDocumentDetailsSaga),
    call(BillingListViewSaga),
    call(DownloadReportsSaga),
    call(BillingDocumentEditSaga),
    call(RouteSchemeSummarySaga),
    call(userEditSaga),
    call(InspectionListSaga),
    call(PaymentsSettingsSaga),
    call(DownloadAssetReportsSaga),
    call(BillingDetailsListSaga),
    call(RoutesTimetableSaga),
    call(RoutesPassportPdf),
    call(UsersLogsSaga),
    call(ApiKeysSaga),
    call(SchedulesStatusSaga),
    call(TrafficMapSagas),
  ]);
}

export default rootSaga;
