import moment from 'moment';
import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';
import { COUNTERAGENTS } from 'src/config/avlUrls';
import {
  INSTALLATIONS,
  RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE
} from 'src/config/assetUrls';
import { Row } from 'src/components/Tables/types';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import { updateItemStatusRequest } from './store/actions';
import InstallationSwitch from '../InstallationSwitch';
import { InstallationStatusEnum } from '../const';

export const INSTALLATION_TABLE_PATH = 'asset.installation.table';
export const INSTALLATION_TABLE_NAME = 'INSTALLATION';

export const TABLE_CONFIG_BASE = {
  reducerPath: INSTALLATION_TABLE_PATH,
  reducerName: INSTALLATION_TABLE_NAME,
  apiUrl: INSTALLATIONS
};

export const getColumns = (isAllowed: boolean) => [
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Дата монтажу',
    value: 'dateInstallation',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragent',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Група обладнання',
    value: 'equipmentGroup',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Встановлене обладнання',
    value: 'installedEquipment',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Хто монтував',
    value: 'responsiblePerson',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Статус документа',
    value: 'status',
    sortable: true,
    width: '10%',
    changeValue: changeStatusColumn
  },
  {
    label: '',
    value: '',
    sortable: false,
    changeValue: (row: Row) =>
      isAllowed ? (
        <InstallationSwitch
          status={row.status}
          date={moment(row.dateInstallation, 'DD.MM.YYYY')}
          dispatchAction={updateItemStatusRequest(TABLE_CONFIG_BASE as any, {
            id: row.id,
            status: InstallationStatusEnum.Draft
          })}
        />
      ) : (
        <></>
      ),
    width: '5%'
  }
];

export const RESPONSIBLE_PERSON_AUTOCOMPLETE =
  'RESPONSIBLE_PERSON_AUTOCOMPLETE';

export const filters = [
  {
    type: FilterType.Date,
    name: 'dateInstallation',
    label: 'Дата',
    datePickerProps: {
      disableFuture: true
    }
  },
  {
    name: 'counteragentId',
    label: 'Підрозділ',
    optionsURL: COUNTERAGENTS
  },
  {
    name: 'responsiblePersonId',
    label: 'Хто монтував',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: RESPONSIBLE_PERSON_AUTOCOMPLETE,
      reducerPath: 'asset.installation.responsibleEmployeeAutocomplete',
      optionsApiUrl: RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE
    }
  },
  {
    name: 'statusId',
    label: 'Статус',
    options: [
      {
        label: 'Зафіксований',
        value: InstallationStatusEnum.Fixed
      },
      {
        label: 'Чернетка',
        value: InstallationStatusEnum.Draft
      }
    ]
  }
];

export const deleteWarningAppendix =
  'Все змонтоване обладнання буде видалене з моніторингу';
