import { createAsyncAction } from 'typesafe-actions';
import { BreakdownsListState } from '../types';
import { BreakdownDetailsState } from '../../BreakdownDetailsView/types';

export const getBreakdown = createAsyncAction(
  '@BREAKDOWN_LIST/GET_BREAKDOWN_REQUEST',
  '@BREAKDOWN_LIST/GET_BREAKDOWN_SUCCESS',
  '@BREAKDOWN_LIST/GET_BREAKDOWN_FAILURE'
)<undefined, BreakdownsListState, Error>();

export const getBreakdownRowData = createAsyncAction(
  '@BREAKDOWN_DOWNLOAD/GET_BREAKDOWN_DOWNLOAD_REQUEST',
  '@BREAKDOWN_DOWNLOAD/GET_BREAKDOWN_DOWNLOAD_SUCCESS',
  '@BREAKDOWN_DOWNLOAD/GET_BREAKDOWN_DOWNLOAD_FAILURE'
)<number, BreakdownDetailsState, Error>();
