import { COUNTERAGENTS, VEHICLE_MODELS_AUTOCOMPLETE } from 'src/config/avlUrls';
import { SystemStatusDTO } from '@eticket/avl-api-contracts/build';
import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';
import {
  FilterConfig,
  FilterType
} from 'src/components/Tables/TableWithPagination/types';
import { VEHICLES_AUTOCOMPLETE_CONFIG } from 'src/views/clearing/inspectionDocuments/InspectionDocumentsListView/const';

export const columns = [
  {
    label: 'Номерний знак',
    value: 'licensePlate',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Модель ТЗ',
    value: 'vehicleModelName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragentName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Водій',
    value: 'driverName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Статус',
    value: 'status',
    sortable: true,
    width: '10%',
    changeValue: changeStatusColumn
  }
];

export const VEHICLES_TABLE_PATH = 'avl.vehicles.table';
export const VEHICLES_TABLE_NAME = 'VEHICLES';

export const VEHICLE_MODEL_AUTOCOMPLETE_NAME =
  'VEHICLE_MODEL_AUTOCOMPLETE_NAME';
export const VEHICLE_MODEL_AUTOCOMPLETE_PATH =
  'avl.vehicles.vehicleModelsAutocomplete';

export const filters: FilterConfig[] = [
  {
    name: 'counteragentId',
    label: 'Підрозділ',
    optionsURL: `${COUNTERAGENTS}?includeDisabled=true`
  },
  {
    name: 'status',
    label: 'Статус',
    options: [
      {
        label: 'Не активний',
        value: SystemStatusDTO.Disabled
      },
      {
        label: 'Активний',
        value: SystemStatusDTO.Enabled
      }
    ]
  },
  {
    name: 'vehicleModelName',
    label: 'Модель ТЗ',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: VEHICLE_MODEL_AUTOCOMPLETE_NAME,
      reducerPath: VEHICLE_MODEL_AUTOCOMPLETE_PATH,
      optionsApiUrl: VEHICLE_MODELS_AUTOCOMPLETE
    },
    value: 'name'
  },
  {
    name: 'licensePlate',
    label: 'Номерний знак',
    type: FilterType.Autocomplete,
    autocompleteConfig: VEHICLES_AUTOCOMPLETE_CONFIG,
    value: 'name'
  }
];
