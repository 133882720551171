import { useDispatch } from 'react-redux';
import { FC } from 'react';
import moment from 'moment';
import { Switch, Tooltip } from '@material-ui/core';
import { getDateDifference } from 'src/utils/date';
import { StatusEnum } from 'src/constants';

const MAX_DAYS_AFTER_INSTALLATION = 200;

interface InstallationSwitchProps {
  status: string;
  date: moment.Moment;
  dispatchAction: Object;
}

const InstallationSwitch: FC<InstallationSwitchProps> = ({
  status,
  date,
  dispatchAction
}) => {
  const dispatch = useDispatch();

  const isDisabled =
    status === StatusEnum.Fixed &&
    getDateDifference(date) > MAX_DAYS_AFTER_INSTALLATION;

  const onChange = () => {
    if (status !== StatusEnum.Draft) {
      dispatch(dispatchAction);
    }
  };

  const switchComponent = (
    <Switch
      disabled={isDisabled}
      checked={status === StatusEnum.Draft}
      onChange={onChange}
    />
  );

  return isDisabled ? (
    <Tooltip
      placement="bottom"
      // @eslint-disable-next-line max-len
      title={`Документ зафіксовано понад ${MAX_DAYS_AFTER_INSTALLATION} днів тому. Редагування заборонене.`}
    >
      <span>{switchComponent}</span>
    </Tooltip>
  ) : (
    switchComponent
  );
};

export default InstallationSwitch;
