import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Tooltip } from '@material-ui/core';
import { Row, TableConfigBase } from 'src/components/Tables/types';
import { StatusEnum } from 'src/constants';
import * as actions from './store/actions';
import { isToggleDateRestricted, TOGGLE_DISABLED_TEXT } from '../const';

interface Props {
  row: Row;
  config: TableConfigBase;
}

const UninstallationSwitchColumn: FC<Props> = ({ row, config }) => {
  const dispatch = useDispatch();

  const isDisabled =
    row.status === StatusEnum.Completed &&
    isToggleDateRestricted(row.uninstallationDate);

  const onChange = () => {
    if (row.status !== StatusEnum.Draft) {
      dispatch(
        actions.updateTableItemStatus.request({
          id: row.id,
          config
        })
      );
    }
  };

  const switchComponent = (
    <Switch
      disabled={isDisabled}
      checked={row.status === StatusEnum.Draft}
      onChange={onChange}
      color="secondary"
    />
  );

  return isDisabled ? (
    <Tooltip placement="bottom" title={TOGGLE_DISABLED_TEXT}>
      <span>{switchComponent}</span>
    </Tooltip>
  ) : (
    switchComponent
  );
};

export default UninstallationSwitchColumn;
