import { generateJsonFile } from 'src/utils/jsonFile';
import { uninstallationFieldsJson } from '../const';
import {
  MobileEquipment,
  StationaryEquipment,
  UninstallationPayload
} from '../UninstallationDetailsView/store/types';

export function generateJsonOfUninstallation(
  data: UninstallationPayload
): string {
  const { stationaryEquipment = [], mobileEquipment = [] } = data;

  const equipments = [
    stationaryEquipment?.map((item: StationaryEquipment) => ({
      equipmentGroup: 'стаціонарне обладнання',
      ...item
    })),
    mobileEquipment?.map((item: MobileEquipment) => ({
      equipmentGroup: 'мобільне обладнання',
      ...item
    }))
  ];

  return (
    equipments &&
    generateJsonFile(uninstallationFieldsJson, { ...data, equipments })
  );
}
