import { useDispatch } from 'react-redux';
import { Switch } from '@material-ui/core';
import { TableConfig } from 'src/components/Tables/TableWithPagination/types';
import * as actions from '../BillingDocumentsListView/store/actions';
import { reverseStatus } from '../const';
import { BillingDocumentStatusEnum } from '../types';

interface BillingDocumentsSwitchProps {
  id: number;
  tableConfig: TableConfig;
  status: BillingDocumentStatusEnum;
}

const BillingDocumentsSwitch = ({
  id,
  tableConfig,
  status
}: BillingDocumentsSwitchProps) => {
  const dispatch = useDispatch();

  const onChange = () =>
    dispatch(
      actions.updateBillingDocumentItemStatus.request({
        id,
        config: tableConfig,
        status: reverseStatus(status)
      })
    );

  return (
    <span data-testid="billing-document-switch">
      <Switch
        checked={status === BillingDocumentStatusEnum.Unfixed}
        onChange={onChange}
      />
    </span>
  );
};

export default BillingDocumentsSwitch;
