import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import { BREAKDOWNS } from 'src/config/assetUrls';
import * as actions from './actions';

export function* getBreakdownDetails({
  payload: id
}: ReturnType<typeof actions.getBreakdownRowData.request>): SagaIterator {
  const { data } = yield call(apiClient.get, `${BREAKDOWNS}/${id}`, {
    retryAction: actions.getBreakdownRowData.request(id)
  });

  yield put(actions.getBreakdownRowData.success(data));
}

export default function* BreakdownListSaga() {
  yield takeEvery(
    getType(actions.getBreakdownRowData.request),
    safe(getBreakdownDetails, actions.getBreakdownRowData.failure)
  );
}
