import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { navigateTo } from 'src/store/actions/app';
import { BREAKDOWNS } from 'src/config/assetUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import { selectNormalizeData } from '../BreakdownsStepView/store/selectors';

export function* registerBreakdown({
  config
}: ReturnType<typeof actions.registerBreakdownRequest>): SagaIterator {
  const breakdownData = yield select(
    selectNormalizeData(config.dataReducerPath)
  );

  const { data: breakdownId } = yield call(
    apiClient.post,
    BREAKDOWNS,
    { ...breakdownData },
    { retryAction: actions.registerBreakdownRequest(config) }
  );

  yield put(navigateTo({ url: `/asset/breakdowns` }));
  yield put(
    enqueueSnackbar({
      key: 'breakdown_create',
      message: 'Несправність створено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.registerBreakdownSuccess(config, breakdownId));
}

export default function* BreakdownCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.registerBreakdownRequest),
    safe(registerBreakdown, actions.registerBreakdownFailure)
  );
}
