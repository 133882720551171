import moment, { unitOfTime } from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Moment from '@date-io/moment';
import { TIME_FORMAT } from '../config';
import { DAY_IN_MS } from '../constants';

const UTC_OFFSET = moment().utcOffset();

export const isDateValid = (date: any) => date && moment(date).isValid();

export const utcToLocal = (
  date: Date | MaterialUiPickersDate | string,
  format: string
): string => {
  if (date && date.toString().indexOf('-') > 10) {
    const dates: string[] = [];
    date
      .toString()
      .split('-')
      .forEach((item: string) => dates.push(moment(item).format(format)));

    return dates.join(' - ');
  }

  return date ? moment(date).format(format) : '';
};

export const convertUtcTimeToLocal = (time: string) =>
  moment(time, TIME_FORMAT)
    .add(moment().utcOffset(), 'minute')
    .format(TIME_FORMAT);

export const convertUtcToLocal = (
  utc: string | Date,
  format: string = 'YYYY-MM-DDTHH:mm:ss'
) =>
  utc
    ? moment
        .utc(utc)
        .local()
        .format(format)
    : '';

export const convertLocalToUtc = (
  local: string | Date,
  format: string = 'YYYY-MM-DDTHH:mm:ss'
) =>
  local
    ? moment(local)
        .utc()
        .format(format)
    : '';

function getDaysArrayByMonth(date: string) {
  let daysInMonth = moment(date).daysInMonth();
  const arrDays = [];

  const month = moment(date).month();
  const year = moment(date).year();

  while (daysInMonth) {
    const current = moment()
      .month(month)
      .year(year)
      .date(daysInMonth)
      .format('yyyy-MM-DD');
    arrDays.push(current);
    // eslint-disable-next-line no-plusplus
    daysInMonth--;
  }

  return arrDays.reverse();
}

export function getWeekArray(d: string) {
  const dayArray: string[] = getDaysArrayByMonth(d);
  const weekArray: (string | null)[][] = [];
  let week: (string | null)[] = [];

  const addWeek = (currentWeek: (string | null)[]) => {
    const emptyDays = 7 - currentWeek.length;
    for (let i = 0; i < emptyDays; ++i) {
      currentWeek[weekArray.length ? 'push' : 'unshift'](null);
    }
    weekArray.push(currentWeek);
  };

  dayArray.forEach(day => {
    if (week.length > 0 && moment(day).day() === 1) {
      addWeek(week);
      week = [];
    }
    week.push(day);
    if (dayArray.indexOf(day) === dayArray.length - 1) {
      addWeek(week);
    }
  });

  return weekArray;
}

export function addMonths(d: string, months: number) {
  return moment(d)
    .add(months, 'M')
    .format('yyyy-MM-DD');
}

export const isDateIn = (datesArray: Date[], date: Date) =>
  datesArray.filter(day => !moment(day).isSame(date, 'day')).length !==
  datesArray.length;

export const isPastDate = (date: string | Date | MaterialUiPickersDate) =>
  moment(date).isBefore(moment().startOf('day'));

export const getTomorrowsUtcDate = (value?: string) =>
  new Moment().addDays(new Moment().date(value), 1).format('yyyy-MM-DD');

export const isTodayDate = (date: Date | MaterialUiPickersDate | string) =>
  moment(date).isSame(moment().startOf('day'), 'day');

export const tomorrowsUtcDate = new Moment()
  .addDays(new Moment().date(), 1)
  .format('yyyy-MM-DD');

export const isValidTime = (time: string, format: string = TIME_FORMAT) =>
  moment(time, format, true).isValid();

export const isTimeBefore = (
  before: string | Date = '',
  after: string | Date = '',
  format: string = TIME_FORMAT
) => moment(before, format).isBefore(moment(after, format));

export const getDateDifference = (
  date: moment.Moment | string,
  unit: unitOfTime.Diff = 'days'
) => {
  const normalizedDate = moment(date, 'DD-MM-YYYY');

  return moment()
    .startOf('day')
    .diff(normalizedDate.startOf('day'), unit);
};

export const getTimeDiffInMs = (
  first: string | Date,
  second: string | Date | number,
  format: string = TIME_FORMAT
) => {
  const diff = moment(first, format).diff(second, 'ms');

  return diff >= 0 ? diff : DAY_IN_MS + diff;
};

export const convertSecondsToTimestamp = (
  seconds: number | string,
  format: string = 'HH:mm:ss'
) =>
  moment(new Date(Math.abs(+seconds) * 1000))
    .utc()
    .format(format);

export const isCurrentTimeBetween = (
  from: string | Date = '',
  to: string | Date = '',
  format?: string
) => {
  const isAfter = isTimeBefore(from, new Date(), format);
  const isBefore = isTimeBefore(new Date(), to, format);
  const isNightTime = isTimeBefore(to, from, format);

  return isNightTime ? isAfter || isBefore : isAfter && isBefore;
};

export const getTime = (date: Date | string, format: string = TIME_FORMAT) =>
  moment(date).format(format);

export const localToUtc = (
  date: string | Date,
  format: string,
  keepLocalTime: boolean = false
) => moment(date, format).utc(keepLocalTime);

// parse time, shift in minutes, format back
export const shiftTime = (
  time: string,
  shift: number = UTC_OFFSET,
  format: string = TIME_FORMAT
) =>
  moment(time, format)
    .add(shift, 'm')
    .format(format);

export const isValidEndDate = (
  startDate: string | null,
  endDate: string | null
) => !endDate || moment(endDate).isAfter(startDate, 'day');

export const yesterday = moment(new Date()).subtract(1, 'day');
