import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { MapBoundPoint } from 'src/types/map';
import { RouterState } from 'connected-react-router';

export const selectUiState = (state: RootState) => state.ui;

export const selectSnackbar = (state: RootState) => state.snackbar;

export const selectIsLoading = (state: RootState) =>
  Object.values(state.loading).some(isLoading => isLoading);

export const selectMapBounds = (state: RootState): MapBoundPoint =>
  state.map.workArea;

export const selectGeozonesState = (state: RootState) => state.map.geozones;
export const selectGeozones = createSelector(
  selectGeozonesState,
  state => state
);

export const selectStops = createSelector(
  selectGeozonesState,
  ({ busStops }) => busStops
);

export const selectUser = (state: RootState) => state.oidc?.user;

export const selectUserPermissions = (state: RootState) =>
  state.oidc?.user?.profile?.Permission || [];

export const selectUserEmployee = (state: RootState) => state.user.employee;

export const selectIsLoadingUser = (state: RootState) =>
  state.oidc?.isLoadingUser;

export const selectUserInitials = (state: RootState) => {
  const fullName = state.oidc?.user?.profile?.name ?? '';
  const [surname, name] = fullName.split(' ');

  return `${surname[0]}${name[0]}`;
};

export const selectIsFormDirty = (state: RootState) => state.form.isDirty;

export const selectCurrentUrl = (state: RootState & { router: RouterState }) =>
  state.router.location.pathname;
