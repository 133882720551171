import { createReducer, ActionType } from 'typesafe-actions';
import { EquipmentTypeEnum, StatusEnum } from 'src/constants';
import * as actions from './actions';
import { BreakdownDetailsState } from '../types';

type BreakdownDetailsActions = ActionType<typeof actions>;

const initialState: BreakdownDetailsState = {
  id: 0,
  documentNumber: 0,
  registrationDate: null,
  registrationEmployeeName: '',
  equipmentGroupName: '',
  reportingEmployeeName: '',
  counteragentName: '',
  equipmentGroupId: EquipmentTypeEnum.Stationary,
  licensePlate: '',
  vehicleModelName: '',
  vehicleCategoryName: '',
  counteragentAddress: '',
  employeeName: '',
  notes: '',
  installationStatusId: StatusEnum.Fixed,
  equipments: [],
  breakdownItems: []
};

const breakdownDetailsReducers = createReducer<
  BreakdownDetailsState,
  BreakdownDetailsActions
>(initialState).handleAction(
  actions.getBreakdown.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default breakdownDetailsReducers;
