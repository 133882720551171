import { flattenDeep } from 'lodash';
import { PdfGenerator, RowItems, TypeTable } from 'src/services/pdfGenerator';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL } from 'src/config';
import { EquipmentTypeEnum, equipmentTypes } from 'src/constants';
import { installationColumnsPdf, installationDocumentPdf } from '../const';
import {
  EquipmentDetail,
  InstallationDetailsState
} from '../InstallationDetailsView/types';

export function generatePdfOfInstallation(
  data: InstallationDetailsState
): PdfGenerator {
  const equipments = flattenDeep(
    data.equipments.map((item: EquipmentDetail) => {
      const { supplements, ...obj } = item;

      if (supplements?.length) {
        return [
          obj,
          ...supplements.map(suppl => {
            return {
              name: suppl
            };
          })
        ];
      }

      return obj;
    })
  );

  let title = '';
  if (data.equipmentGroupId === EquipmentTypeEnum.Mobile) {
    title = `${data.licensePlate}`;
  }

  const installationDocument: RowItems[] = [];

  if (
    data.equipmentGroupName === equipmentTypes[EquipmentTypeEnum.Stationary] ||
    data.equipmentGroupName === equipmentTypes[EquipmentTypeEnum.Inspector]
  ) {
    installationDocumentPdf.forEach(
      item =>
        !['licensePlate', 'vehicleModelName', 'vehicleCategoryName'].includes(
          item.value
        ) && installationDocument.push(item)
    );
  }

  if (data.equipmentGroupName === equipmentTypes[EquipmentTypeEnum.Mobile]) {
    installationDocumentPdf.forEach(
      item => item.value !== 'employeeName' && installationDocument.push(item)
    );
  }

  return new PdfGenerator(
    `Монтаж №${data.documentNumber ||
      ''} ${title} ${data.installingEmployeeName || ''} від ${utcToLocal(
      data.installationDate,
      DATE_FORMAT_FULL
    )}`
  )
    .setHeader(
      `Акт виконаних робіт № ${data.documentNumber || ''} від ${utcToLocal(
        data.installationDate,
        DATE_FORMAT_FULL
      )}`,
      true
    )
    .setTable(installationDocument, data, TypeTable.InfoTable)
    .setHeader('Встановлене/Видане Обладнання', false)
    .setTable(installationColumnsPdf, equipments, TypeTable.ListTable);
}
