import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { API_BASE_URL } from 'src/config';
import * as snackbarActions from 'src/store/actions/snackbar';

const apiClient = axios.create({ baseURL: API_BASE_URL });

export const axiosInterceptor = (store: any) => {
  apiClient.interceptors.request.use(config => {
    const token = store.getState().oidc.user?.access_token;

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`
      }
    };
  });

  apiClient.interceptors.response.use(
    response => response,
    error => {
      const {
        UNPROCESSABLE_ENTITY,
        INTERNAL_SERVER_ERROR,
        BAD_GATEWAY,
        SERVICE_UNAVAILABLE,
        GATEWAY_TIMEOUT
      } = StatusCodes;

      if (error.response.status === UNPROCESSABLE_ENTITY) {
        return Promise.reject(error.response.data.errors);
      }

      if (error.response.status === SERVICE_UNAVAILABLE) {
        window.location.href = '/503';
        return Promise.reject(error.response.data.errors);
      }

      store.dispatch(
        snackbarActions.enqueueSnackbar({
          message: 'Виникла помилка',
          options: {
            variant: 'error'
          },
          ...(error.config.retryAction &&
            error.response &&
            [
              INTERNAL_SERVER_ERROR,
              BAD_GATEWAY,
              SERVICE_UNAVAILABLE,
              GATEWAY_TIMEOUT
            ].includes(error.response.status) && {
              retryAction: () => store.dispatch(error.config.retryAction),
              message: 'Виникла помилка, cпробуйте знову'
            })
        })
      );

      return Promise.reject(error);
    }
  );
};

export default apiClient;
