import { createCustomAction } from 'typesafe-actions';

interface EditBreakdownConfig {
  dataReducerPath: string;
  tableReducerPath: string;
}

export const updateBreakdownRequest = createCustomAction(
  '@BREAKDOWN/UPDATE_BREAKDOWN_REQUEST',
  (config: EditBreakdownConfig) => ({
    config
  })
);

export const updateBreakdownSuccess = createCustomAction(
  '@BREAKDOWN/UPDATE_BREAKDOWN_SUCCESS',
  (config: EditBreakdownConfig) => ({ config })
);

export const updateBreakdownFailure = createCustomAction(
  '@BREAKDOWN/UPDATE_BREAKDOWN_FAILURE',
  (config: EditBreakdownConfig, payload: Error) => ({ config, payload })
);
