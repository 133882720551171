import { createAsyncAction, createCustomAction } from 'typesafe-actions';
import { TableConfigBase } from 'src/components/Tables/types';
import { InstallationStatusEnum } from '../../const';
import { InstallationDetailsState } from '../../InstallationDetailsView/types';

export interface Payload {
  id: number;
  status: InstallationStatusEnum;
}

export const getInstallationRowData = createAsyncAction(
  '@INSTALLATIONS_SEND_EMAIL/GET_INSTALLATION_SEND_EMAIL_REQUEST',
  '@INSTALLATIONS_SEND_EMAIL/GET_INSTALLATION_SEND_EMAIL_SUCCESS',
  '@INSTALLATIONS_SEND_EMAIL/GET_INSTALLATION_SEND_EMAIL_FAILURE'
)<{ id: string }, InstallationDetailsState, Error>();

export const getSelectedInstallationsData = createAsyncAction(
  '@INSTALLATIONS/GET_SELECTED_INSTALLATIONS_REQUEST',
  '@INSTALLATIONS/GET_SELECTED_INSTALLATIONS_SUCCESS',
  '@INSTALLATIONS/GET_SELECTED_INSTALLATIONS_FAILURE'
)<{ id: string }[], InstallationDetailsState[], Error>();

export const updateInstallationStatus = createAsyncAction(
  '@INSTALLATION/UPDATE_INSTALLATION_STATUS_REQUEST',
  '@INSTALLATION/UPDATE_INSTALLATION_STATUS_SUCCESS',
  '@INSTALLATION/UPDATE_INSTALLATION_STATUS_FAILURE'
)<Payload, undefined, Error>();

export const updateItemStatusRequest = createCustomAction(
  '@INSTALLATION/UPDATE_ITEM_STATUS_REQUEST',
  (config: TableConfigBase, payload: Payload) => ({ config, payload })
);

export const updateItemStatusSuccess = createCustomAction(
  '@INSTALLATION/UPDATE_ITEM_STATUS_SUCCESS',
  (config: TableConfigBase) => ({ config })
);

export const updateItemStatusFailure = createCustomAction(
  '@INSTALLATION/UPDATE_ITEM_STATUS_FAILURE',
  (config: TableConfigBase, payload: Error) => ({ config, payload })
);
