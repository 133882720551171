import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { navigateTo } from 'src/store/actions/app';
import { INSTALLATIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import { selectNormalizedList } from '../EquipmentStepView/store/selectors';
import { selectNormalizeData } from '../InstallationStepView/store/selectors';

export function* registerInstallation({
  config,
  resolve
}: ReturnType<typeof actions.registerInstallationRequest>): SagaIterator {
  const installationData = yield select(
    selectNormalizeData(config.dataReducerPath)
  );
  const equipment = yield select(selectNormalizedList(config.tableReducerPath));

  const { data: installationId } = yield call(
    apiClient.post,
    INSTALLATIONS,
    { ...installationData, equipment },
    { retryAction: actions.registerInstallationRequest(config, resolve) }
  );

  yield call(resolve, installationId);
  yield put(
    navigateTo({ url: `/asset/installation/create/${installationId}` })
  );
  yield put(actions.registerInstallationSuccess(config, installationId));
}

export default function* InstallationCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.registerInstallationRequest),
    safe(registerInstallation, actions.registerInstallationFailure)
  );
}
