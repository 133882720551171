import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { BREAKDOWNS } from 'src/config/assetUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getBreakdown(): SagaIterator {
  const id = yield select(selectors.selectBreakdownId);

  const { data } = yield call(apiClient.get, `${BREAKDOWNS}/${id}`, {
    retryAction: actions.getBreakdown.request
  });

  yield put(actions.getBreakdown.success(data));
}

export function* updateBreakdownStatus({
  payload
}: ReturnType<typeof actions.updateBreakdownState.request>): SagaIterator {
  yield put(actions.updateBreakdownState.request(payload));
  yield put(actions.getBreakdown.request());
  yield put(actions.updateBreakdownState.success());
}

export default function* BreakdownDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getBreakdown.request),
      safe(getBreakdown, actions.getBreakdown.failure)
    ),
    takeEvery(
      getType(actions.updateBreakdownState.request),
      safe(updateBreakdownStatus, actions.updateBreakdownState.failure)
    )
  ]);
}
