import { combineReducers } from 'redux';
import { createNamedWrapperReducer } from 'src/utils/reducers';
import tableWithPaginationReducers from 'src/components/Tables/TableWithPagination/store/reducers';
import tableViewReducers from 'src/components/Tables/TableView/store/reducers';
import tableReducers from 'src/components/Tables/Table/store/reducers';
import autocompleteReducers from 'src/components/Form/FormAutocomplete/store/reducer';
import breakdownsTypesDetailsReducer from 'src/views/asset/breakdownsTypes/BreakdownsTypesDetailsView/store/reducers';
import equipmentEditReducers from 'src/views/asset/equipment/EquipmentEditView/store/reducers';
import { EQUIPMENT_TABLE_NAME } from 'src/views/asset/equipment/EquipmentListView/const';
import { BREAKDOWNS_TYPES_TABLE_NAME } from 'src/views/asset/breakdownsTypes/BreakdownsTypesListView/const';
import breakdownsTypesEditReducers from 'src/views/asset/breakdownsTypes/BreakdownsTypesEditView/store/reducer';
import {
  BREAKDOWNS_TABLE_NAME,
  REGISTERED_EMPLOYEE_NAME
} from 'src/views/asset/breakdowns/BreakdownsListView/const';
import equipmentDetailsReducer from 'src/views/asset/equipment/EquipmentDetailsView/store/reducers';
import {
  CREATE_INSTALLATION_FORM_NAME,
  INSTALLING_EQUIPMENT_NAME
} from 'src/views/asset/installation/InstallationCreateView/const';
import {
  BREAKDOWNS_EQUIPMENT_TABLE_NAME,
  BREAKDOWNS_EQUIPMENT_NAME,
  CREATE_BREAKDOWNS_FORM_NAME
} from 'src/views/asset/breakdowns/BreakdownsCreateView/const';
import {
  BREAKDOWNS_BREAKDOWN_SELECT_NAME,
  BREAKDOWNS_EQUIPMENT_SELECT_NAME
} from 'src/views/asset/breakdowns/BreakdownsCreateView/EquipmentStepView/const';
import {
  EDIT_INSTALLATION_FORM_NAME,
  EDIT_INSTALLING_EQUIPMENT_NAME
} from 'src/views/asset/installation/InstallationEditView/const';
import {
  ACCEPTING_EMPLOYEES_NAME,
  COUNTERAGENTS_NAME,
  EMPLOYEES_NAME,
  VEHICLE_NUMBERS_NAME
} from 'src/views/asset/installation/InstallationCreateView/InstallationStepView/const';
import { EQUIPMENT_NAME } from 'src/views/asset/installation/InstallationCreateView/EquipmentStepView/const';
import { INSTALLATION_EQUIPMENT_TABLE_NAME } from 'src/views/asset/installation/InstallationCreateView/ConfirmStepView/const';
import installationStepReducers from 'src/views/asset/installation/InstallationCreateView/InstallationStepView/store/reducer';
import uninstallationStepReducers from 'src/views/asset/uninstallation/UninstallationStep/store/reducer';
import {
  CREATE_UNINSTALLATION_FORM_NAME,
  UNINSTALLING_MOBILE_EQUIPMENT_NAME,
  UNINSTALLING_STATIONARY_EQUIPMENT_NAME
} from 'src/views/asset/uninstallation/UninstallationCreateView/const';
import {
  EDIT_UNINSTALLATION_FORM_NAME,
  EDIT_UNINSTALLING_MOBILE_EQUIPMENT_NAME,
  EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_NAME
} from 'src/views/asset/uninstallation/UninstallationEditView/const';
import { UNINSTALLING_EMPLOYEES_NAME } from 'src/views/asset/uninstallation/UninstallationStep/const';
import uninstallationDetailsReducers from 'src/views/asset/uninstallation/UninstallationDetailsView/store/reducer';
import inspectionsFormReducer from 'src/views/asset/inspections/InspectionFormStep/store/reducer';
import {
  INSPECTION_COUNTERAGENTS_NAME,
  COUNTERAGENT_VEHICLES_NAME,
  PROFESSIONS_NAME,
  INSPECTOR_EMPLOYEES_NAME
} from 'src/views/asset/inspections/InspectionFormStep/const';
import { INSPECTIONS_EQUIPMENTS_SELECT_NAME } from 'src/views/asset/inspections/InspectionsEquipmentStep/const';
import {
  CREATE_INSPECTION_FORM_NAME,
  INSPECTIONS_EQUIPMENT_NAME
} from 'src/views/asset/inspections/InspectionsCreateView/const';
import {
  EDIT_INSPECTION_FORM_NAME,
  EDIT_INSPECTIONS_EQUIPMENT_NAME
} from 'src/views/asset/inspections/InspectionEditView/const';
import { EQUIPMENT_GROUP_SELECT_NAME } from 'src/views/asset/equipment/const';
import selectReducers from 'src/components/Select/store/reducers';
import {
  INSTALLATION_TABLE_NAME,
  RESPONSIBLE_PERSON_AUTOCOMPLETE
} from 'src/views/asset/installation/InstallationListView/const';
import {
  TRACKING_MOBILE_TABLE_NAME,
  TRACKING_STATIONARY_TABLE_NAME
} from 'src/views/asset/tracking/TrackingListView/const';
import installationDetailsReducers from 'src/views/asset/installation/InstallationDetailsView/store/reducers';
import installationEmailDataReducers from 'src/views/asset/installation/InstallationListView/store/reducers';
import {
  RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME,
  UNINSTALLATION_TABLE_NAME
} from 'src/views/asset/uninstallation/UninstallationListView/const';
import inspectionDetailsReducers from 'src/views/asset/inspections/InspectionDetailsView/store/reducer';
import { INSPECTIONS_TABLE_NAME } from 'src/views/asset/inspections/InspectionsListView/const';
import {
  REPORT_COUNTERAGENT_SELECT_NAME,
  REPORT_ACTIONS_SELECT_NAME,
  REPORT_EQUIPMENTS_SELECT_NAME,
  REPORT_EQUIPMENT_GROUPS_SELECT_NAME,
  REPORT_VEHICLE_CATEGORIES_SELECT_NAME,
  REPORT_EMPLOYEES_AUTOCOMPLETE_NAME,
  REPORTS_TABLE_NAME,
  DOWNLOAD_REPORTS,
  BARCODE_AUTOCOMPLETE_NAME
} from 'src/views/asset/reports/const';
import breakdownStepReducer from 'src/views/asset/breakdowns/BreakdownsCreateView/BreakdownsStepView/store/reducers';
import breakdownDetailsReducers from 'src/views/asset/breakdowns/BreakdownDetailsView/store/reducers';
import {
  EDIT_BREAKDOWNS_EQUIPMENT_NAME,
  EDIT_BREAKDOWN_FORM_NAME
} from 'src/views/asset/breakdowns/BreakdownsEditView/const';
import downloadReportsReducer from '../../views/asset/reports/store/reducers';

const assetReducers = combineReducers({
  installation: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        installationStepReducers,
        CREATE_INSTALLATION_FORM_NAME
      ),
      table: createNamedWrapperReducer(
        tableViewReducers,
        INSTALLING_EQUIPMENT_NAME
      ),
      equipment: createNamedWrapperReducer(
        tableReducers,
        INSTALLATION_EQUIPMENT_TABLE_NAME
      )
    }),
    edit: combineReducers({
      data: createNamedWrapperReducer(
        installationStepReducers,
        EDIT_INSTALLATION_FORM_NAME
      ),
      table: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_INSTALLING_EQUIPMENT_NAME
      )
    }),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      INSTALLATION_TABLE_NAME
    ),
    acceptingEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      ACCEPTING_EMPLOYEES_NAME
    ),
    counteragentsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      COUNTERAGENTS_NAME
    ),
    employeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      EMPLOYEES_NAME
    ),
    vehicleNumbersAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      VEHICLE_NUMBERS_NAME
    ),
    equipmentAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      EQUIPMENT_NAME
    ),
    responsibleEmployeeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      RESPONSIBLE_PERSON_AUTOCOMPLETE
    ),
    details: installationDetailsReducers,
    emailData: installationEmailDataReducers
  }),
  breakdowns: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        breakdownStepReducer,
        CREATE_BREAKDOWNS_FORM_NAME
      ),
      breakdownsTable: createNamedWrapperReducer(
        tableViewReducers,
        BREAKDOWNS_EQUIPMENT_NAME
      ),
      equipment: createNamedWrapperReducer(
        tableReducers,
        BREAKDOWNS_EQUIPMENT_TABLE_NAME
      )
    }),
    breakdownSelect: createNamedWrapperReducer(
      selectReducers,
      BREAKDOWNS_BREAKDOWN_SELECT_NAME
    ),
    equipmentSelect: createNamedWrapperReducer(
      selectReducers,
      BREAKDOWNS_EQUIPMENT_SELECT_NAME
    ),
    vehicleNumbersAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      VEHICLE_NUMBERS_NAME
    ),
    acceptingEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      ACCEPTING_EMPLOYEES_NAME
    ),
    counteragentsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      COUNTERAGENTS_NAME
    ),
    registeredEmployeeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      REGISTERED_EMPLOYEE_NAME
    ),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      BREAKDOWNS_TABLE_NAME
    ),
    details: breakdownDetailsReducers,
    edit: combineReducers({
      data: createNamedWrapperReducer(
        breakdownStepReducer,
        EDIT_BREAKDOWN_FORM_NAME
      ),
      breakdownsTable: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_BREAKDOWNS_EQUIPMENT_NAME
      ),
      equipment: createNamedWrapperReducer(
        tableReducers,
        BREAKDOWNS_EQUIPMENT_TABLE_NAME
      )
    })
  }),
  uninstallation: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        uninstallationStepReducers,
        CREATE_UNINSTALLATION_FORM_NAME
      ),
      mobileTable: createNamedWrapperReducer(
        tableViewReducers,
        UNINSTALLING_MOBILE_EQUIPMENT_NAME
      ),
      stationaryTable: createNamedWrapperReducer(
        tableViewReducers,
        UNINSTALLING_STATIONARY_EQUIPMENT_NAME
      )
    }),
    edit: combineReducers({
      data: createNamedWrapperReducer(
        uninstallationStepReducers,
        EDIT_UNINSTALLATION_FORM_NAME
      ),
      mobileTable: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_UNINSTALLING_MOBILE_EQUIPMENT_NAME
      ),
      stationaryTable: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_NAME
      )
    }),
    uninstallingEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      UNINSTALLING_EMPLOYEES_NAME
    ),
    responsibleEmployeeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME
    ),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      UNINSTALLATION_TABLE_NAME
    ),
    details: uninstallationDetailsReducers
  }),
  inspections: combineReducers({
    create: combineReducers({
      data: createNamedWrapperReducer(
        inspectionsFormReducer,
        CREATE_INSPECTION_FORM_NAME
      ),
      equipmentTable: createNamedWrapperReducer(
        tableViewReducers,
        INSPECTIONS_EQUIPMENT_NAME
      )
    }),
    edit: combineReducers({
      data: createNamedWrapperReducer(
        inspectionsFormReducer,
        EDIT_INSPECTION_FORM_NAME
      ),
      equipmentTable: createNamedWrapperReducer(
        tableViewReducers,
        EDIT_INSPECTIONS_EQUIPMENT_NAME
      )
    }),
    details: inspectionDetailsReducers,
    inspectorEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INSPECTOR_EMPLOYEES_NAME
    ),
    professions: createNamedWrapperReducer(selectReducers, PROFESSIONS_NAME),
    counteragentsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      INSPECTION_COUNTERAGENTS_NAME
    ),
    counteragentVehiclesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      COUNTERAGENT_VEHICLES_NAME
    ),
    equipmentsSelect: createNamedWrapperReducer(
      selectReducers,
      INSPECTIONS_EQUIPMENTS_SELECT_NAME
    ),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      INSPECTIONS_TABLE_NAME
    )
  }),
  equipment: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      EQUIPMENT_TABLE_NAME
    ),
    edit: equipmentEditReducers,
    create: combineReducers({
      equipmentGroupSelect: createNamedWrapperReducer(
        selectReducers,
        EQUIPMENT_GROUP_SELECT_NAME
      )
    }),
    details: equipmentDetailsReducer
  }),
  breakdownsTypes: combineReducers({
    details: breakdownsTypesDetailsReducer,
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      BREAKDOWNS_TYPES_TABLE_NAME
    ),
    edit: breakdownsTypesEditReducers
  }),
  tracking: combineReducers({
    mobileTable: createNamedWrapperReducer(
      tableWithPaginationReducers,
      TRACKING_MOBILE_TABLE_NAME
    ),
    stationaryTable: createNamedWrapperReducer(
      tableWithPaginationReducers,
      TRACKING_STATIONARY_TABLE_NAME
    )
  }),
  reports: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      REPORTS_TABLE_NAME
    ),
    reportCounteragentSelect: createNamedWrapperReducer(
      selectReducers,
      REPORT_COUNTERAGENT_SELECT_NAME
    ),
    reportActionSelect: createNamedWrapperReducer(
      selectReducers,
      REPORT_ACTIONS_SELECT_NAME
    ),
    reportEquipmentSelect: createNamedWrapperReducer(
      selectReducers,
      REPORT_EQUIPMENTS_SELECT_NAME
    ),
    reportEquipmentGroupSelect: createNamedWrapperReducer(
      selectReducers,
      REPORT_EQUIPMENT_GROUPS_SELECT_NAME
    ),
    reportVehicleCategoriesSelect: createNamedWrapperReducer(
      selectReducers,
      REPORT_VEHICLE_CATEGORIES_SELECT_NAME
    ),
    reportEmployeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      REPORT_EMPLOYEES_AUTOCOMPLETE_NAME
    ),
    reportsDownload: createNamedWrapperReducer(
      downloadReportsReducer,
      DOWNLOAD_REPORTS
    ),
    barcodeAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      BARCODE_AUTOCOMPLETE_NAME
    )
  })
});

export default assetReducers;
