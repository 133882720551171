import { createAsyncAction } from 'typesafe-actions';
import { BreakdownDetailsState } from '../types';
import { UpdateBreakdownPayload } from '../../const';

export const getBreakdown = createAsyncAction(
  '@BREAKDOWN_DETAILS/GET_BREAKDOWN_REQUEST',
  '@BREAKDOWN_DETAILS/GET_BREAKDOWN_SUCCESS',
  '@BREAKDOWN_DETAILS/GET_BREAKDOWN_FAILURE'
)<undefined, BreakdownDetailsState, Error>();

export const updateBreakdownState = createAsyncAction(
  '@BREAKDOWN_DETAILS/UPDATE_BREAKDOWN_STATE_REQUEST',
  '@BREAKDOWN_DETAILS/UPDATE_BREAKDOWN_STATE_SUCCESS',
  '@BREAKDOWN_DETAILS/UPDATE_BREAKDOWN_STATE_FAILURE'
)<UpdateBreakdownPayload, undefined, Error>();
