import { UNINSTALLATIONS } from 'src/config/assetUrls';

export const EDIT_UNINSTALLATION_FORM_NAME = 'EDIT_UNINSTALLATION_FORM';
export const EDIT_UNINSTALLATION_FORM_PATH = 'asset.uninstallation.edit.data';
export const EDIT_UNINSTALLATION_FORM_CONFIG = {
  reducerName: EDIT_UNINSTALLATION_FORM_NAME,
  reducerPath: EDIT_UNINSTALLATION_FORM_PATH
};

export const EDIT_UNINSTALLING_MOBILE_EQUIPMENT_NAME =
  'EDIT_UNINSTALLING_MOBILE_EQUIPMENT_NAME';
export const EDIT_UNINSTALLING_MOBILE_EQUIPMENT_PATH =
  'asset.uninstallation.edit.mobileTable';

export const EDIT_UNINSTALLING_MOBILE_TABLE_CONFIG = (id: number) => ({
  reducerName: EDIT_UNINSTALLING_MOBILE_EQUIPMENT_NAME,
  reducerPath: EDIT_UNINSTALLING_MOBILE_EQUIPMENT_PATH,
  apiUrl: `${UNINSTALLATIONS}/${id}/equipment/mobile`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено демонтаж'
  },
  enableSetRowsIsLocalProperty: true
});

export const EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_NAME =
  'EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_NAME';
export const EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_PATH =
  'asset.uninstallation.edit.stationaryTable';

export const EDIT_UNINSTALLING_STATIONARY_TABLE_CONFIG = (id: number) => ({
  reducerName: EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_NAME,
  reducerPath: EDIT_UNINSTALLING_STATIONARY_EQUIPMENT_PATH,
  apiUrl: `${UNINSTALLATIONS}/${id}/equipment/stationary`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено демонтаж'
  },
  enableSetRowsIsLocalProperty: true
});

export const content =
  'Ви впевнені, що хочете завершити демонтаж? Все вибране обладнання буде зняте з відстеження.';
