import { createSelector } from 'reselect';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/asset/dictionaries/equipment/:id/details'
);

export const selectEquipmentId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectIsLoadingEquipment = (state: RootState) => {
  const loading = get(state, `loading.@EQUIPMENTS_DETAILS/GET_EQUIPMENT`);

  return isBoolean(loading) ? loading : true;
};

export const selectEquipmentName = (state: RootState) =>
  state.ui.asset.equipment.details.name;

export const selectEquipmentRows = (state: RootState) => {
  const {
    name,
    groupName,
    inspectionDays,
    hasImei,
    manufacturer,
    productType
  } = state.ui.asset.equipment.details;

  return [
    { label: 'Назва обладнання', value: name },
    { label: 'Назва групи', value: groupName },
    { label: 'Періодичність інспекцій, дні', value: inspectionDays || 0 },
    { label: 'Виробник', value: manufacturer },
    { label: 'Модель', value: productType },
    { label: 'IMEI', value: hasImei ? 'Є' : 'Немає' }
  ];
};

export const selectIsEquipmentDeleteDisabled = (state: RootState) =>
  state.ui.asset.equipment.details.isInstalled;
