import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { InspectionDetails } from '../../InspectionDetailsView/types';
import * as actions from './actions';
import { fetchInspectionDetails } from '../../InspectionDetailsView/store/sagas';

export function* getInspectionDetails({
  payload: id
}: ReturnType<typeof actions.getInspectionPdfDetails.request>): SagaIterator {
  const data: InspectionDetails = yield call(fetchInspectionDetails, id);

  yield put(actions.getInspectionPdfDetails.success(data));
}

export default function* InspectionListSaga() {
  yield takeEvery(
    getType(actions.getInspectionPdfDetails.request),
    safe(getInspectionDetails, actions.getInspectionPdfDetails.failure)
  );
}
