import { array, number, object, string } from 'yup';
import {
  AVL_ERROR_MESSAGES,
  ERROR_MESSAGES,
  phoneNumberRegExp
} from 'src/constants/validation';
import { LAT_LON_REGEX } from 'src/constants/map';
import {
  COUNTERAGENTS,
  COUNTERAGENTS_CUSTOM_TYPES,
  COUNTERAGENTS_TYPES,
  PARENT_COUNTERGAGENTS
} from 'src/config/avlUrls';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { maxLength } from 'src/utils/validation';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  },
  {
    text: NAV_BAR_ITEMS.geozones,
    url: '/avl/geozones/counteragents'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.counteragents,
    url: '/avl/geozones/counteragents'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'avl',
    sub: 'counteragents'
  },
  ['download']
);

export const counteragentsValidationsSchema = object().shape({
  name: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(...maxLength(100)),
  shortName: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(...maxLength(60)),
  code: string()
    .trim()
    .max(...maxLength(20))
    .when('$editMode', {
      is: true,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    }),
  departmentTypeId: number().required(ERROR_MESSAGES.REQUIRED),
  status: string().required(ERROR_MESSAGES.REQUIRED),
  description: string().max(...maxLength(255)),
  address: string()
    .trim()
    .when('$isDepotSelected', {
      is: true,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    })
    .max(...maxLength(255)),
  coordinates: string()
    .when('$isDepotSelected', {
      is: true,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    })
    .matches(
      LAT_LON_REGEX,
      'Невірний формат координат. Для збереження точки введіть дані у форматі ХХ.ХХХХХХ (широта), ХХ.ХХХХХХ (довгота) або вкажіть точку безпосередньо на мапі'
    ),
  contacts: array().of(
    object().shape({
      email: string()
        .max(...maxLength(60))
        .email(ERROR_MESSAGES.TYPE_ERROR)
        .required(ERROR_MESSAGES.REQUIRED),
      phoneNumber: string()
        .required(ERROR_MESSAGES.REQUIRED)
        .matches(phoneNumberRegExp, ERROR_MESSAGES.TYPE_ERROR),
      description: string().max(...maxLength(255))
    })
  )
});

export const COUNTERAGENTS_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  UniqueError: AVL_ERROR_MESSAGES.uniqueError,
  forbidden: undefined
};

export const COUNTERAGENTS_SNACKBAR_ERRORS = {
  forbidden: 'Даний підрозділ використовується для діючих маршрутів',
  unprocessableEntity:
    'Даний підрозділ використовується як перевізник для діючих маршрутів'
} as Record<string, string>;

export const COUNTERAGENTS_SELECT_NAME = 'COUNTERAGENTS_SELECT';
export const COUNTERAGENTS_SELECT_PATH = 'avl.counteragents.select';
export const COUNTERAGENTS_SELECT_CONFIG = (id?: string) => ({
  optionsApiUrl: id ? PARENT_COUNTERGAGENTS(id) : COUNTERAGENTS,
  reducerName: COUNTERAGENTS_SELECT_NAME,
  reducerPath: COUNTERAGENTS_SELECT_PATH,
  prohibitDynamicLoad: true
});

export const COUNTERAGENT_TYPE_SELECT_NAME = 'TYPES_SELECT';
export const COUNTERAGENT_TYPE_SELECT_PATH = 'avl.counteragents.editableSelect';
export const COUNTERAGENTS_TYPES_CONFIG = {
  reducerPath: COUNTERAGENT_TYPE_SELECT_PATH,
  reducerName: COUNTERAGENT_TYPE_SELECT_NAME,
  customOptionsApiUrl: COUNTERAGENTS_CUSTOM_TYPES,
  activeOptionsApiUrl: COUNTERAGENTS_TYPES
};
export const COUNTERAGENTS_DIALOG_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  UniqueError: ERROR_MESSAGES.UNIQUE
};

export const DEPOT = 'Депо';
