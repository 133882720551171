import { createAsyncAction } from 'typesafe-actions';
import { InstallationDetailsState } from '../types';
import { Payload } from '../../InstallationListView/store/actions';

export const getInstallation = createAsyncAction(
  '@INSTALLATIONS_DETAILS/GET_INSTALLATION_REQUEST',
  '@INSTALLATIONS_DETAILS/GET_INSTALLATION_SUCCESS',
  '@INSTALLATIONS_DETAILS/GET_INSTALLATION_FAILURE'
)<undefined, InstallationDetailsState, Error>();

export const updateInstallationState = createAsyncAction(
  '@INSTALLATIONS_DETAILS/UPDATE_INSTALLATION_STATE_REQUEST',
  '@INSTALLATIONS_DETAILS/UPDATE_INSTALLATION_STATE_SUCCESS',
  '@INSTALLATIONS_DETAILS/UPDATE_INSTALLATION_STATE_FAILURE'
)<Payload, undefined, Error>();
