import { RootState } from 'typesafe-actions';
import { get } from 'lodash';
import { createSelector } from 'reselect';

export const selectFormValues = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}`, {});

export const selectFormCreateData = (reducerPath: string) =>
  createSelector(
    selectFormValues(reducerPath),
    ({ uninstallationDate, notes, uninstallingEmployee = {} }) => ({
      notes,
      uninstallationDate,
      uninstallingEmployeeId: uninstallingEmployee.id
    })
  );
