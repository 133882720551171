import { RootState } from 'typesafe-actions';
import { get, isBoolean } from 'lodash';
import { OptionsData } from '../types';

export const selectList = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.list`);

export const selectSorters = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.sorters`);

export const selectFilters = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.filters`);

export const selectSelection = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.selection`);

export const selectIsLoading = (name: string) => (state: RootState) => {
  const loading = get(state, `loading.@TABLE_WITH_PAGINATION/GET_DATA/${name}`);

  return isBoolean(loading) ? loading : true;
};

export const selectFilterOptions = (
  reducerPath: string,
  filterName: string,
  filterValue?: string
) => (state: RootState) => {
  const options = get(
    state,
    `ui.${reducerPath}.appliedFilters["${filterName}"]`
  );

  if (!options) return [];

  return options.map((option: OptionsData) => ({
    label: option.name,
    value: filterValue ? option[filterValue] : option.id
  }));
};
