import { createCustomAction } from 'typesafe-actions';
import {
  CounteragentResponse,
  VehicleModelResponse,
  InitialState,
  InstallationStepConfig,
  InstallationDataPayload
} from './types';

export const getCounteragentRequest = createCustomAction(
  '@INSTALLATION_STEP/GET_COUNTERAGENT_REQUEST',
  (config: InstallationStepConfig, payload: number) => ({
    config,
    payload
  })
);

export const getCounteragentSuccess = createCustomAction(
  '@INSTALLATION_STEP/GET_COUNTERAGENT_SUCCESS',
  (config: InstallationStepConfig, payload: CounteragentResponse) => ({
    config,
    payload
  })
);

export const getCounteragentFailure = createCustomAction(
  '@INSTALLATION_STEP/GET_COUNTERAGENT_FAILURE',
  (config: InstallationStepConfig, payload: Error) => ({
    config,
    payload
  })
);

export const getVehicleModelRequest = createCustomAction(
  '@INSTALLATION_STEP/GET_VEHICLE_MODEL_REQUEST',
  (config: InstallationStepConfig, payload: number) => ({
    config,
    payload
  })
);

export const getVehicleModelSuccess = createCustomAction(
  '@INSTALLATION_STEP/GET_VEHICLE_MODEL_SUCCESS',
  (config: InstallationStepConfig, payload: VehicleModelResponse) => ({
    config,
    payload
  })
);

export const getVehicleModelFailure = createCustomAction(
  '@INSTALLATION_STEP/GET_VEHICLE_MODEL_FAILURE',
  (config: InstallationStepConfig, payload: Error) => ({
    config,
    payload
  })
);

export const getInstallationDataRequest = createCustomAction(
  '@INSTALLATION_PAGE/GET_DATA_REQUEST',
  (config: InstallationStepConfig) => ({ config })
);

export const getInstallationDataSuccess = createCustomAction(
  '@INSTALLATION_PAGE/GET_DATA_SUCCESS',
  (config: InstallationStepConfig, payload: InstallationDataPayload) => ({
    config,
    payload
  })
);

export const getInstallationDataFailure = createCustomAction(
  '@INSTALLATION_PAGE/GET_DATA_FAILURE',
  (config: InstallationStepConfig, payload: Error) => ({ config, payload })
);

export const setFormValues = createCustomAction(
  '@INSTALLATION_STEP/SET_FORM_VALUES',
  (config: InstallationStepConfig, values: InitialState) => ({
    config,
    values
  })
);
